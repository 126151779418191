// someReducer.ts
import { createSlice } from "@reduxjs/toolkit";

interface SomeState {
  isFormUpdated: boolean; // Add isFormDataUpdated to the statez
}

const initialState: SomeState = {
  isFormUpdated: false, // Initialize isFormDataUpdated as false
};

const formUpdateSlice = createSlice({
  name: "formUpdate",
  initialState,
  reducers: {
    setFormData: (state) => {
      state.isFormUpdated = true; // Set isFormDataUpdated to true when someData changes
    },
    resetFormData: (state) => {
      state.isFormUpdated = false; // Set isFormDataUpdated to false when resetting data
    },
  },
});

export const { setFormData, resetFormData } = formUpdateSlice.actions;

export default formUpdateSlice.reducer;
