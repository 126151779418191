import React, { useEffect, useState } from "react";
import FormContent from "../../../components/Form/FormContent";
import { formBuilder } from "../../../utils/formGenerator";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { useFormik } from "formik";
import {
  makeStyles,
  shorthands,
  Button,
  tokens,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Label,
  Card,
  CardHeader,
  CardPreview,
} from "@fluentui/react-components";
import {
  Edit16Filled,
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  DismissCircleRegular,
  DismissCircle20Regular,
} from "@fluentui/react-icons";
import apiServices from "../../../service";
import { setFormData, resetFormData } from "../../../redux/reducer";
import axios from "axios";
import CalculationTable from "../../../components/CalculationTable/fundCalculation";
import { checkUserPermission, formatedDate2 } from "../../../utils";
import { UserPermissionsList } from "../../../config";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import PaymentCalculation from "../../../components/CalculationTable/paymentCalculation";
import OverlayLoader from "../../../components/OverlayLoader";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";

const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};
type FormContainerDetailPageProps = {
  style?: React.CSSProperties;
  title?: string;
  mode?: string;
  context: string;
  [x: string]: any;
};
const FormContainerDetailPage = (props: FormContainerDetailPageProps) => {
  let { context } = props;
  const classes = useStyles();
  const [configForm, setConfigForm] = useState<configFm>();
  const [formContext, setFormContext] = useState("");
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [optionData, setOptionData] = useState<any>();
  const [optionList, setOptionList] = useState<any>([]);
  const [isOptionLoaded, setIsOptionLoaded] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const [formError, setFormError] = useState<any[]>([]);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [getConsultant, setgetConsultant] = useState("");
  const [getCommissionType, setgetCommissionType] = useState("");

  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    // console.log("field", field);
    // console.log("options", options);
    // console.log("selectFieldOptions", selectFieldOptions);
    // console.log("selectFieldOptionLabels", selectFieldOptionLabels);
    // let fieldInfo = selectFieldOptions.find((e: any) => e.field === field);
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    // console.log("labelInfo", labelInfo);
    // console.log("fieldInfo", fieldInfo);
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
    // console.log("newlyAddedSelectOptions", newlyAddedSelectOptions);
  };

  const updateConsultantName = (name: any) => {
    setgetConsultant(name);
  };

  const updateCommissionType = (name: any) => {
    setgetCommissionType(name);
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      setLoading(true);
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          setLoading(false);
          // console.log("response--add options", response);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          // console.log("index", index);
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });
        setLoading(true);
        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            setLoading(false);
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
            // console.log("response-selectionlist res", res.data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }

      // const response = await axios.get("/option2.json");
      // const optiondata = response.data;
      // if (optiondata.data && optiondata.data.length) {
      //   setSelectFieldOptions(optiondata.data);
      // }

      // console.log("optiondata", optiondata);
      // setOptionData(optiondata);
      // optiondata[context] && setOptionList(optiondata[context]);
      // optionList && setIsOptionLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (context !== "") {
      setFormContext(context);
      generateDynamicForm(context);
    }
    // const fetchOption = async () => {
    //   try {
    //     const response = await axios.get("/option.json");
    //     const optiondata = response.data;
    //     setOptionData(optiondata);
    //     optiondata[context] && setOptionList(optiondata[context]);
    //     optionList && setIsOptionLoaded(true);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };

    // fetchOption();
  }, [
    context,
    formSettingsObj,
    props.detailPageMode,
    props.liabilityActiveTabLevel2Subtab,
  ]);
  const customHandleChange = (event: any) => {
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;
    // dispatch(setFormData());

    // if (["title", "first_name", "last_name"].includes(event.target.name)) {
    //   formik.setFieldValue("salutation", getSalutations(data));
    // }
    updatePresent(data);
    // if (isFormDataUpdated) {
    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      updated = false;
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
      updated = true;
    }
    // }

    if (props.mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(props.mode === "add" ? "New" : "");
    }
  };
  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  const undo = () => {
    if (past.length === 0) {
      setPast([]);
      return;
    }
    // if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const generateDynamicForm = (formContext: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let { initialValues, inputs, validationSchema } = formBuilder({
        context: context,
        formSettings: formSettingsObj.data,
      });

      let filteredSelectionOptions = inputs
        .filter(
          (opt: any) =>
            opt.listType !== undefined &&
            [
              "ModifiableList",
              "FixedList",
              "MasterDatawithNewEntry",
              "MasterDatawithoutNewEntry",
            ].includes(opt.listType) &&
            opt.fieldOptionLabel !== undefined
        )
        .map((opt) => ({
          label: opt.fieldOptionLabel,
          listType: opt.listType,
          formField: opt.field,
        }));
      // console.log("filteredSelectionOptions", filteredSelectionOptions);
      setSelectFieldOptionLabels(filteredSelectionOptions);
      if (filteredSelectionOptions.length > 0) {
        getSelectionListOptions(filteredSelectionOptions);
      }

      if (
        props.detailPageMode &&
        ["add", "edit"].includes(props.detailPageMode) &&
        props.liabilityActiveTabLevel2Subtab &&
        props.liabilityActiveTabLevel2Subtab === "paymentCommission"
      ) {
        inputs = inputs.filter((item: any) => item.field !== "status");
      }
      setFormFieldData({ initialValues, inputs, validationSchema });
      let configObj = formSettingsObj.data.formConfig.find(
        (item: any) => item.context === context
      );

      if (configObj) {
        setConfigForm(configObj);
      }
    }
  };

  const formik = useFormik({
    initialValues:
      props.mode && ["edit", "view"].includes(props.mode)
        ? props.initialFormData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},

    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  const formSubmitted = (values: any) => {
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    let reqBody: { [key: string]: any } = {};
    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
      if (reqBody.commission_type !== undefined) {
        setgetCommissionType(reqBody.commission_type);
      }
    }
    let addReq: any = {};
    if (
      configForm &&
      configForm.renderForm &&
      configForm.renderForm.reference_id
    ) {
      addReq[configForm.renderForm.reference_id] = reqBody;
      if (props.liabilityActiveTabLevel2 === "payment") {
        if (props.liabilityActiveTabLevel2Subtab === "paymentCommission") {
          addReq[configForm.renderForm.reference_id]["customer_id"] =
            props.activeItem.customer_id;
          addReq[configForm.renderForm.reference_id]["case_id"] =
            props.activeItem.liability_id;
          addReq[configForm.renderForm.reference_id]["payment_id"] =
            props.activePaymentItem.payment.payment_id;
          addReq[configForm.renderForm.reference_id]["commission_type"] =
            getCommissionType === ""
              ? reqBody.commission_type
              : getCommissionType;
          addReq[configForm.renderForm.reference_id]["payment_source"] =
            reqBody.payment_source;
          addReq[configForm.renderForm.reference_id]["amount_to_company"] =
            parseFloat(reqBody.amount_to_company);
          addReq[configForm.renderForm.reference_id]["frequency"] =
            reqBody.frequency;
          addReq[configForm.renderForm.reference_id]["start_date"] =
            formatedDate2(reqBody.start_date);
          addReq[configForm.renderForm.reference_id]["end_date"] =
            formatedDate2(reqBody.end_date);
          addReq[configForm.renderForm.reference_id]["nb_register"] = parseInt(
            reqBody.nb_register
          );
          addReq[configForm.renderForm.reference_id]["payment_count"] =
            reqBody.payment_count;
          addReq[configForm.renderForm.reference_id]["status"] =
            parseInt(reqBody.status) || 0;
          // addReq[configForm.renderForm.reference_id]["terms_of_business_sent"] =
          //   reqBody.show_amt_on_nb_register;
          addReq[configForm.renderForm.reference_id]["note_text"] =
            reqBody.note_text;
        } else {
          addReq[configForm.renderForm.reference_id]["customer_id"] =
           props.activeItem.customer_id;
          addReq[configForm.renderForm.reference_id]["case_id"] =
            props.activeItem.liability_id;
          addReq[configForm.renderForm.reference_id]["consultant"] =
            getConsultant === "" ? reqBody.consultant : getConsultant;

          addReq[configForm.renderForm.reference_id]["start_date"] =
            reqBody.start_date;
          addReq[configForm.renderForm.reference_id]["end_date"] =
            reqBody.end_date;
          addReq[configForm.renderForm.reference_id]["amount"] = parseFloat(
            reqBody.amount
          );
          addReq[configForm.renderForm.reference_id]["frequency"] =
            reqBody.frequency;
          addReq[configForm.renderForm.reference_id]["reinvestment"] =
            reqBody.reinvestment;
          addReq[configForm.renderForm.reference_id]["note_text"] =
            reqBody.note_text;
          addReq[configForm.renderForm.reference_id]["payment_type"] =
            reqBody.payment_type;
          if (reqBody.new_business_register) {
            addReq[configForm.renderForm.reference_id][
              "new_business_register"
            ] = reqBody.new_business_register;
          }
        }
      } else if (props.liabilityActiveTabLevel2Subtab === "paymentcomplaince") {
        addReq[configForm.renderForm.reference_id]["consultant_id"] =
          reqBody.consultant_id;
        addReq[configForm.renderForm.reference_id]["business_card"] =
          reqBody.business_card;
        addReq[configForm.renderForm.reference_id]["client_agreement"] =
          reqBody.client_agreement;
        addReq[configForm.renderForm.reference_id][
          "data_protection_form_sent"
        ] = reqBody.data_protection_form_sent;
        addReq[configForm.renderForm.reference_id]["fact_find_completed"] =
          reqBody.fact_find_completed;
        addReq[configForm.renderForm.reference_id]["high_risk"] =
          reqBody.high_risk;
        addReq[configForm.renderForm.reference_id]["high_risk_note"] =
          reqBody.high_risk_note;
        addReq[configForm.renderForm.reference_id]["type_of_sale"] =
          reqBody.type_of_sale;
        addReq[configForm.renderForm.reference_id]["business_card"] =
          reqBody.business_card;
        addReq[configForm.renderForm.reference_id]["client_agreement"] =
          reqBody.client_agreement;
        addReq[configForm.renderForm.reference_id]["terms_of_business_sent"] =
          reqBody.terms_of_business_sent;
        addReq[configForm.renderForm.reference_id]["idd"] = reqBody.idd;
        addReq[configForm.renderForm.reference_id]["menu"] = reqBody.menu;
        addReq[configForm.renderForm.reference_id][
          "data_protection_form_sent"
        ] = reqBody.data_protection_form_sent;
        addReq[configForm.renderForm.reference_id]["risk_profile"] =
          reqBody.risk_profile;
        addReq[configForm.renderForm.reference_id]["verification_of_identity"] =
          reqBody.verification_of_identity;
        addReq[configForm.renderForm.reference_id]["research_note"] =
          reqBody.research_note;
        addReq[configForm.renderForm.reference_id]["illustration"] =
          reqBody.illustration;
        addReq[configForm.renderForm.reference_id]["research_completed"] =
          reqBody.research_completed;
        addReq[configForm.renderForm.reference_id]["key_features_sent"] =
          reqBody.key_features_sent;
        addReq[configForm.renderForm.reference_id]["suitability_letter_sent"] =
          reqBody.suitability_letter_sent;
        addReq[configForm.renderForm.reference_id]["docs_received"] =
          reqBody.docs_received;
        addReq[configForm.renderForm.reference_id]["docs_sent"] =
          reqBody.docs_sent;
      } else if (props.liabilityActiveTabLevel2 === "actions") {
        addReq[configForm.renderForm.reference_id]["customer_id"] =
         props.activeItem.customer_id;
        addReq[configForm.renderForm.reference_id]["case_id"] =
          props.activeItem.liability_id;
        addReq[configForm.renderForm.reference_id]["business_date"] =
          reqBody.business_date;
        addReq[configForm.renderForm.reference_id]["business_action"] =
          reqBody.business_action;
        addReq[configForm.renderForm.reference_id]["business_assigned"] =
          reqBody.business_assigned;
        addReq[configForm.renderForm.reference_id]["business_status"] =
          reqBody.business_status;
        // liabilities_mortgages_liability
        configForm.renderForm.reference_id === "liabilities_mortgages_actions"
          ? (addReq[configForm.renderForm.reference_id][
              "business_category"
            ] = 6)
          : configForm.renderForm.reference_id ===
            "liabilities_loan_hire_purchase_actions"
          ? (addReq[configForm.renderForm.reference_id][
              "business_category"
            ] = 7)
          : (addReq[configForm.renderForm.reference_id][
              "business_category"
            ] = 0);
      } else {
      }
    }

    if (props.detailPageMode === "add") {
      setLoading(true);
      setFormError([]);
      if (props.liabilityActiveTabLevel2 === "payment") {
        if (props.liabilityActiveTabLevel2Subtab === "paymentCommission") {
          apiServices.commission
            .addCommission(addReq)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else {
          apiServices.liability
            .addPayment(addReq)
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        }
      } else if (props.liabilityActiveTabLevel2 === "actions") {
        apiServices.liability
          .addActions(addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else {
      }
      //  add api
    } else if (props.detailPageMode === "edit") {
      setLoading(true);
      setFormError([]);
      if (props.liabilityActiveTabLevel2 === "payment") {
        if (props.liabilityActiveTabLevel2Subtab === "paymentCommission") {
          apiServices.commission
            .updateCommission(
              props.liabilitySubActiveItem.commission_id,
              addReq
            )
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }

              // if (response.data) {
              //   // if(response.data.data){
              //   props.notify(response.data.message, "", "success");
              //   props.handleReloadSublist();
              //   // }
              // }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        } else {
          apiServices.liability
            .updatePayment(
              props.liabilitySubActiveItem.payment.payment_id,
              addReq
            )
            .then((response) => {
              setLoading(false);
              if (!response.data.isError) {
                props.notify(response.data.message, "", "success");
                if (props.handleReloadSublist) {
                  props.handleReloadSublist();
                  AddSelectOptions();
                }
              } else if (response.data.isError) {
                if (
                  response.data.error &&
                  Object.keys(response.data.error).length !== 0
                ) {
                  if (Array.isArray(response.data.error.message)) {
                    setFormError(response.data.error.message);
                  } else {
                    setFormError((prev) => [response.data.error.message]);
                  }
                }
              } else {
                props.notify("Something went wrong", "", "error");
              }

              // props.notify(response.data.message, "", "success");
              // props.handleReloadSublist();
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              props.notify(err.message, "", "error");
            });
        }
      } else if (props.liabilityActiveTabLevel2 === "paymentscomplaince") {
        apiServices.liability
          .updatePayment(props.liabilitySubActiveItem.payment_id, addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }

            // props.notify(response.data.message, "", "success");
            // props.handleReloadSublist();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else if (props.liabilityActiveTabLevel2 === "actions") {
        apiServices.liability
          .updateActions(props.liabilitySubActiveItem.business_id, addReq)
          .then((response) => {
            setLoading(false);
            if (!response.data.isError) {
              props.notify(response.data.message, "", "success");
              if (props.handleReloadSublist) {
                props.handleReloadSublist();
                AddSelectOptions();
              }
            } else if (response.data.isError) {
              if (
                response.data.error &&
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                if (Array.isArray(response.data.error.message)) {
                  setFormError(response.data.error.message);
                } else {
                  setFormError((prev) => [response.data.error.message]);
                }
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }

            // props.notify(response.data.message, "", "success");
            // props.handleReloadSublist();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            props.notify(err.message, "", "error");
          });
      } else {
      }
    } else {
    }
  };

  console.log("partner", props);
  return (
    <>
      <OverlayLoader isLoading={loading} />
      {context !== undefined && context !== "" && (
        <>
          {props.mode && props.mode === "view" && (
            <>
              {props.liabilityActiveTabLevel2 === "payment" ? (
                <PaymentCalculation calculatedData={props.paymentValue} />
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div>
                  Record {props?.currentSubRecord} of{" "}
                  {props.liabilitySubitemsList.length}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {checkUserPermission(
                      loggedUser,
                      UserPermissionsList.ALLOW_UPDATE_CLIENT
                    ) && (
                      <Button
                        style={{ marginRight: 2, marginBottom: 6 }}
                        icon={<Edit16Filled />}
                        size="small"
                        onClick={() => {
                          props.setDetailPageMode("edit");
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <div>
                    {checkUserPermission(
                      loggedUser,
                      UserPermissionsList.ALLOW_UPDATE_CLIENT
                    ) && (
                      <Button
                        icon={<Delete16Regular />}
                        style={{
                          marginRight: 2,
                          marginBottom: 6,
                          color: "red",
                        }}
                        size="small"
                        onClick={() => {
                          dispatch(
                            setDialogModalOptions({
                              open: true,
                              content_line_1: "Do you want to delete?",
                              no: {
                                onclick: () => {
                                  dispatch(
                                    setDialogModalOptions({ open: false })
                                  );
                                },
                                label: "No",
                                type: "primary",
                              },
                              cancel: {
                                onclick: () => {
                                  dispatch(
                                    setDialogModalOptions({ open: false })
                                  );
                                },
                                label: "Yes",
                              },
                            })
                          );
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {formError && formError.length > 0 && (
            <FormErrorMessage
              errorMessages={formError}
              onDismiss={() => setFormError([])}
            />
          )}
          {props.mode && ["edit", "add"].includes(props.mode) && (
            <div className={classes.btnwrapper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Label
                  size="medium"
                  weight="semibold"
                  className={classes.message}
                >
                  {formMode !== "" && (
                    <>
                      <span>**</span> {formMode} <span>**</span>
                    </>
                  )}
                </Label>
              </div>

              <div>
                <Button
                  icon={<ArrowUndo16Regular />}
                  onClick={undo}
                  disabled={!isFormDataUpdated}
                ></Button>
                <Button
                  icon={<ArrowRedo16Regular />}
                  onClick={redo}
                  disabled={future.length === 0 ? true : false}
                ></Button>
              </div>
              <Button
                disabled={!isFormDataUpdated}
                appearance="transparent"
                onClick={() => {
                  formik.resetForm();
                  dispatch(resetFormData());
                }}
              >
                Reset All
              </Button>
              {props.mode &&
                props.mode === "edit" &&
                props.showListItems === false && (
                  <Button
                    appearance="transparent"
                    icon={<Delete16Regular />}
                    onClick={() => {
                      props.setDialogModal({
                        ...props.dialogModalOptions,
                        open: true,
                        content_line_1: "Do you want to delete?",
                        no: {
                          onclick: () => {
                            props.setDialogModal({
                              ...props.dialogModalOptions,
                              open: false,
                            });
                          },
                          label: "No",
                        },
                        cancel: {
                          onclick: () => {
                            props.setDialogModal({
                              ...props.dialogModalOptions,
                              open: false,
                            });
                          },
                          label: "Yes",
                          type: "primary",
                        },
                      });
                    }}
                  ></Button>
                )}
            </div>
          )}
          <div style={{ marginTop: 10 }}>
            <FormContent
              {...{
                formik,
                formFieldData,
                title: "",
                mode: props.mode,
                handleOnChange,
                optionList,
                customHandleChange,
                context,
                selectFieldOptions,
                newSelectOptionAdded,
                updateConsultantName,
                updateCommissionType,
                activePartnerId: props.activePartnerId,
                setActivePartnerId: props.setActivePartnerId,
              }}
            />

            {props.mode !== "view" && (
              <div className={classes.buttonWrapper}>
                <Button
                  className="asc-button-primary"
                  appearance="primary"
                  // disabled={props.defaultAction === true ? true : false}
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  onClick={() =>
                    isFormDataUpdated ? formik.handleSubmit() : ""
                  }
                >
                  Save
                </Button>
                <Button
                  onClick={() => {
                    if (isFormDataUpdated) {
                      console.log(props.mode, "mode");
                      console.log(props.liabilitySubitemsList.length, "length");
                      console.log(isFormDataUpdated, "form");
                      dispatch(
                        setDialogModalOptions({
                          open: true,
                          content_line_1:
                            "We have detected an attempt to move away from the current page.",
                          content_line_2:
                            "This would cause all changes made to be lost",
                          cancel: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                              dispatch(resetFormData());
                              props.liabilitySubitemsList.length === 0
                                ? props.setMode("")
                                : props.setMode("view");
                            },
                            label: "Leave the Page",
                          },

                          no: {
                            onclick: () => {
                              dispatch(setDialogModalOptions({ open: false }));
                            },
                            label: "Stay on Page",
                            type: "primary",
                          },
                        })
                      );
                    } else {
                      formik.resetForm();
                      props.liabilitySubitemsList.length === 0
                        ? props.setMode("")
                        : props.setMode("view");
                    }
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormContainerDetailPage;
