import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import "./style.css";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Label,
  Input,
  ToolbarButton,
} from "@fluentui/react-components";
import { Dismiss16Regular } from "@fluentui/react-icons";
import ReactQuill from "react-quill";
import { MasterDataTable } from "../MasterDataTable";
import ContactHistortForm from "../../pages/client/ContactHistory/ContactHistortForm";
import ActionListForm from "../../pages/Actionlist/ActionListForm";
import apiServices from "../../service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import Provider from "../../pages/client/Provider/Provider";
import LoadStandardAction from "../LoadStandardAction";
import CustomerSelection from "../../pages/report/CustomerSelection";
import ActionListDateFilter from "../../pages/Actionlist/ActionListDateFilter";

export const ModalDialog = (props: any) => {
  const [isDelete, setIsDelete] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState("");
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [bulkData, setBulkData] = useState<any>({});
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (props.options?.table_type === "objectives") {
      setBulkData({
        Objective: {
          customer_id: props.options?.customerId,
          objectives: checkedItems,
        },
      });
    } else if (props.options?.table_type === "income") {
      setBulkData({
        income: {
          customer_id: props.options?.customerId,
          income_categories: checkedItems,
        },
      });
    } else if (props.options?.table_type === "outgoings") {
      setBulkData({
        outgoings: {
          customer_id: props.options?.customerId,
          expense_categories: checkedItems,
        },
      });
    } else if (props.options?.table_type === "attitudeToRisk") {
      setBulkData({
        attitude_to_risk: {
          customer_id: props.options?.customerId,
          attitude_risks: checkedItems,
        },
      });
    }
  }, [checkedItems]);

  function onDelete(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
    const string = e.target.value.toLowerCase();
    if (string === "yes") setIsDelete(false);
    else setIsDelete(true);
  }

  function onApply() {
    if (props.options?.table_type === "objectives") {
      apiServices.getDefaultobjective.add(bulkData).then(() => {
        setCheckedItems([]);
        props.options?.reload();
      });
    } else if (props.options?.table_type === "income") {
      apiServices.getDefaultIncome.add(bulkData).then(() => {
        setCheckedItems([]);
        props.options?.reload();
      });
    } else if (props.options?.table_type === "outgoings") {
      apiServices.getDefaultexpense.add(bulkData).then(() => {
        setCheckedItems([]);
        props.options?.reload();
      });
    } else if (props.options?.table_type === "attitudeToRisk") {
      apiServices.getDefaultAttitudeToRisk.add(bulkData).then(() => {
        setCheckedItems([]);
        props.options?.reload();
      });
    }
  }
  return (
    <Dialog open={props.options?.open}>
      <DialogSurface
        style={
          props.options?.contact_history_data
            ? { minWidth: "75vw" }
            : { width: "75vw" }
        }
      >
        <DialogBody>
          {/* {props.options && props.options?.close && (
          <ToolbarButton
            aria-label="Close panel"
            appearance="subtle"
            icon={<Dismiss16Regular />}
            onClick={props.options?.close.onclick()}
          />
        )} */}
          <DialogTitle>
            {props.options?.title}
            {props.options && props.options?.close && (
              <div className="close-button-end-parent">
                <Dismiss16Regular
                  className="close-button-end"
                  onClick={() => props.options?.close.onclick()}
                />
              </div>
            )}
          </DialogTitle>
          <DialogContent style={{ marginBottom: "12px" }}>
            {props.options?.content_line_1 && (
              <div>
                <Label size="large" weight="semibold">
                  {props.options?.content_line_1}
                </Label>
              </div>
            )}

            {props.options?.showMore_text && (
              <ReactQuill
                value={DOMPurify.sanitize(props.options?.showMore_text)}
                readOnly={true}
                theme={"bubble"}
              />
            )}

            {props.options?.client_table && (
              <CustomerSelection
                customerSelection={props.options?.customerSelection}
              />
            )}

            {props.options?.date_filter && (
              <ActionListDateFilter
                dateSelection={props.options?.dateSelection}
                dateLabel={props.options?.dateLabel}
                optionDateValue={props.options?.optionDateValue}
                optionDate={props.options?.optionDate}
              />
            )}

            {props.options?.contact_history_data && (
              <ContactHistortForm
                data={props.options?.contact_history_data}
                customerId={props.options?.customerId}
                type={props.options?.type}
                setActiveItem={props.options?.setActiveItem}
                activeClient={props.options?.activeClient}
                case_type={
                  props.options?.case_type ? props.options?.case_type : ""
                }
              />
            )}
            {props.options?.load_action && (
              <LoadStandardAction
                updateSelectedActions={
                  props.options?.updateSelectedActions
                    ? props.options?.updateSelectedActions
                    : undefined
                }
                type={props.options?.load_action_type}
              />
            )}
            {props.options?.provider && (
              <Provider
                providerId={props.options?.providerId}
                options={props.options}
              />
            )}

            {props.options?.action_list_data && (
              <ActionListForm
                data={props.options?.action_list_data}
                cancelPopup={props.options?.cancel.onclick}
                getActionListData={props.getActionListData}
              />
            )}

            {props.options?.master_table && (
              <MasterDataTable
                setCheckedItems={setCheckedItems}
                checkedItems={checkedItems}
                type={props.options?.table_type}
              />
            )}

            {props.options?.content_line_2 && (
              <div>
                <Label size="medium" weight="regular">
                  {props.options?.content_line_2}
                </Label>
              </div>
            )}
            {props.options?.content_line_3 && (
              <div>
                <Label size="medium" weight="regular">
                  {props.options?.content_line_3}
                </Label>
              </div>
            )}
            {props.options?.deleteInput && (
              <div>
                <Input size="small" value={inputValue} onChange={onDelete} />
              </div>
            )}
          </DialogContent>
          {!props.options?.action_list_data && (
            <DialogActions>
              {props.options && props.options?.deleteInput && (
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    className="asc-button-primary"
                    appearance="primary"
                    onClick={() => {
                      props.options?.deleteInput.onclickYes();
                      setInputValue("");
                      setIsDelete(true);
                    }}
                    disabled={isDelete}
                  >
                    {props.options?.deleteInput.labelYes}
                  </Button>
                </DialogTrigger>
              )}

              {props.options && props.options?.deleteInput && (
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secondary"
                    onClick={() => {
                      props.options?.deleteInput.onclickNo();
                      setInputValue("");
                      setIsDelete(true);
                    }}
                  >
                    {props.options?.deleteInput.labelNo}
                  </Button>
                </DialogTrigger>
              )}

              {props.options && props.options?.yes && (
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    className={`${
                      props.options?.yes.type === "primary"
                        ? "asc-button-primary"
                        : ""
                    }`}
                    appearance={
                      props.options?.yes.type === "primary"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => {
                      props.options?.yes.onclick();
                    }}
                  >
                    {props.options?.yes.label}
                  </Button>
                </DialogTrigger>
              )}

              {props.options && props.options?.apply && (
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    size="small"
                    className={`${
                      props.options?.apply.type === "primary"
                        ? "asc-button-primary"
                        : ""
                    }`}
                    appearance={
                      props.options?.apply.type === "primary"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => {
                      onApply();
                      props.options?.apply.onclick();
                    }}
                  >
                    {props.options?.apply.label}
                  </Button>
                </DialogTrigger>
              )}

              {props.options && props.options?.no && (
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    size="small"
                    className={`${
                      props.options?.no.type === "primary"
                        ? "asc-button-primary"
                        : ""
                    }`}
                    appearance={
                      props.options?.no.type === "primary"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => {
                      props.options?.no.onclick();
                    }}
                  >
                    {props.options?.no.label}
                  </Button>
                </DialogTrigger>
              )}
              {props.options && props.options?.cancel && (
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    size="small"
                    className={`${
                      props.options?.cancel.type === "primary"
                        ? "asc-button-primary"
                        : ""
                    }`}
                    appearance={
                      props.options?.cancel.type === "primary"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() => {
                      props.options?.cancel.onclick();
                      setCheckedItems([]);
                    }}
                  >
                    {props.options?.cancel.label}
                  </Button>
                </DialogTrigger>
              )}
            </DialogActions>
          )}
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
export default ModalDialog;
