import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";

import "../style.css";
import ModalDialog from "../../../components/ModalDialog";
import IncomeCategoriesForm from "./IncomeCategories/IncomeCategoriesForm";
import ExpenseCategoriesForm from "./ExpenseCategories/ExpenseCategoriesForm";
import AttitudeToRiskCategories from "./AttitudeToRiskCategories/AttitudeToRiskCategories";
import AttitudeToRiskRatings from "./AttitudeToRiskRatings/AttitudeToRiskRatings";
import {
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastTrigger,
} from "@fluentui/react-components";
import ObjectivesForm from "./Objectives/ObjectivesForm";
import CaseActionForm from "./CaseAction/CaseActionForm";
import ClientActionForm from "./ClientAction/ClientActionFrom";
import { checkUserPermission } from "../../../utils";
import { UserPermissionsList } from "../../../config";
import { useNavigate, useParams } from "react-router-dom";
import UserDefinedForm from "./UserDefinedForm/userDefinedForm";
import SideBar from "./SideBar";
interface PropsType {
  setActiveMenu: Dispatch<SetStateAction<string>>;
  isMaster: boolean;
}

const MasterSettingsPage = () => {
  const { section } = useParams();
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [activeSideMenu, setActiveSideMenu] = useState("Company Details");
  const sideMenuList = [
    "Company Details",
    "Income Categories",
    "Expense Categories",
    "Attitude to risk-Categories",
    "Attitude to risk-Ratings",
    "Objectives",
    "Providers",
    "User Defined",
    "Case Action",
    "Client Action",
    "Email Template",
    "Letter Template",
    "Contract Enquiry",
    "Integrations",
  ];
  const navigate = useNavigate();

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      {/* {checkUserPermission(
        loggedUser,
        UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
      ) && <SideBar section={section} />} */}
      {section === "income-category" && (
        <IncomeCategoriesForm notify={notify} />
      )}
      {section === "expense-category" && (
        <ExpenseCategoriesForm notify={notify} />
      )}
      {section === "attitude-risk-categories" && (
        <AttitudeToRiskCategories notify={notify} />
      )}
      {section === "attitude-risk-ratings" && (
        <AttitudeToRiskRatings notify={notify} />
      )}
      {section === "objectives" && <ObjectivesForm notify={notify} />}
      {section === "case-action" && <CaseActionForm notify={notify} />}
      <ModalDialog options={dialogModalOptions} />
      {section === "client-action" && <ClientActionForm notify={notify} />}
      {section === "user-defined" && <UserDefinedForm notify={notify} />}
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
};

export default MasterSettingsPage;
