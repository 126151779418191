// store.ts
import { configureStore } from "@reduxjs/toolkit";
import someReducer from "./reducer";
import formSlice from "./formSlice";
import authSlice from "./authSlice";
import appSettingsSlice from "./appSettingsSlice";
import modelDialogReducer from "./modelDialogReducer";
import formUpdateReducer from "./formUpdateReducer"

const store = configureStore({
  reducer: {
    someReducer: someReducer,
    formSettings: formSlice,
    authUser: authSlice,
    appSettings:appSettingsSlice,
    dialog: modelDialogReducer,
    formUpdated : formUpdateReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
