import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import apiServices from "../../../service";

const Provider = (props: any) => {
  // const [provderData, setProviderData] = useState([]);
  const [providerCategory, setProviderCategory] = useState([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectedItem, setSelectedItem] = useState<any | {}>(props.data);
  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "group_name",
        header: "Group",
      },
      {
        accessorKey: "provider_name",
        header: "Name",
      },
      {
        accessorKey: "contact_name",
        header: "Contact",
      },

      {
        accessorKey: "address1",
        header: "Address 1",
      },
      {
        accessorKey: "address2",
        header: "Address 2",
      },
      {
        accessorKey: "address3",
        header: "Address 3",
      },
      {
        accessorKey: "town",
        header: "Town",
      },
      {
        accessorKey: "county",
        header: "County",
      },
      {
        accessorKey: "postcode",
        header: "Postcode",
      },
      {
        accessorKey: "salutation",
        header: "Salutation",
      },
      {
        accessorKey: "fax",
        header: "Fax",
      },
      {
        accessorKey: "mobile",
        header: "Mobile",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );

  const getDataList = () => {
    apiServices.systemManager.providers
      .getList()
      .then((res) => {
        if (res?.data?.data) setProviderCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (providerCategory.length && Object.keys(rowSelection)[0]) {
      let index = 0;
      index = providerCategory.findIndex(
        (x: any) => x.id === Object.keys(rowSelection)[0]
      );
      if (providerCategory[index] !== undefined) {
        setSelectedItem(providerCategory[index]);
        props.providerId(providerCategory[index]);
      }
    }
  }, [rowSelection]);

  useEffect(() => {
    if (
      props.options !== undefined &&
      Object.keys(props.options).length !== 0 &&
      props.options.getProviderId !== undefined &&
      providerCategory.length > 0
    ) {
      let index = 0;

      if (props.options.getProviderId["provider_correspondence"]) {
        index = providerCategory.findIndex(
          (x: any) =>
            x.id == props.options.getProviderId["provider_correspondence"]
        );
      } else {
        index = providerCategory.findIndex(
          (x: any) => x.id == props.options.getProviderId["id"]
        );
      }

      if (providerCategory[index] !== undefined) {
        setRowSelection({ [providerCategory[index]["id"]]: true });
        setSelectedItem(providerCategory[index]);
        props.providerId(providerCategory[index]);
      }
    }
  }, [props.options, providerCategory]);

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <div className="client-list-wrapper">
      <MaterialReactTable
        columns={columns}
        data={providerCategory}
        onRowSelectionChange={setRowSelection}
        getRowId={(row) => row.id}
        enableColumnFilters={false}
        enableGlobalFilter={false}
        enableDensityToggle={false}
        enableRowSelection={true}
        enableSelectAll={false}
        enableColumnActions={false}
        enableMultiRowSelection={false}
        enableRowActions={false}
        enableFullScreenToggle={false}
        enableHiding={false}
        state={{ rowSelection }}
        enablePagination={false}
      />
    </div>
  );
};

export default Provider;