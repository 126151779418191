import { Dispatch, SetStateAction, useEffect } from "react";
import "./style.css";
import { Outlet } from "react-router-dom";

interface PropsType {
  setTabs: Dispatch<SetStateAction<string>>;
}

const SystemManagerLayout = ({ setTabs }: PropsType) => {
  useEffect(() => {
    setTabs("systemManager");
  }, []);
  
  return <Outlet />
};

export default SystemManagerLayout;
