import { useEffect, useState } from "react";
import apiServices from "../../../service";

interface PropsType {
  [x: string]: any;
}

const ContactHistoryDetails = (props: PropsType) => {
  const [clientDataById, setClientDataById] = useState<PropsType>([]);
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (props.columnType === "Notes" || props.columnType ==="Client") {
      getClientDetail(props.customerId);
    }
  }, []);


  const getClientDetail = (id: string) => {
    setLoader(false);
    apiServices.client
      .getDetail(id)
      .then((response: any) => {
        setClientDataById(response.data.data);
        setLoader(true);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <div className="case_detail_text">
      <div>
        {props.columnType === "Notes" || props.columnType ==="Client" ? (
          loader && (
            <div>
              <p>
                Title: <span>{clientDataById?.NameAndAddress.title}</span>
              </p>
              <p>
                First Name(s):{" "}
                <span>{clientDataById?.NameAndAddress.first_names}</span>
              </p>
              <p>
                Initials: <span>{clientDataById?.NameAndAddress.initials}</span>
              </p>
              <p>
                Last Name:{" "}
                <span>{clientDataById?.NameAndAddress.last_name}</span>
              </p>
              <p>
                Former Last Name:{" "}
                <span>{clientDataById?.NameAndAddress.former_last_name}</span>
              </p>
              <p>
                Honours: <span>{clientDataById?.NameAndAddress.honours}</span>
              </p>
              <p>
                Known As: <span>{clientDataById?.NameAndAddress.known_as}</span>
              </p>
              <p>
                <div className="street-flex">
                  Street Address:
                  <span>
                    <div>
                      {clientDataById?.NameAndAddress.address_1}
                      <br /> {
                        clientDataById?.NameAndAddress.address_2
                      } <br /> {clientDataById?.NameAndAddress.address_3}
                    </div>
                  </span>
                </div>
              </p>
              <p>
                Town: <span>{clientDataById?.NameAndAddress.town}</span>
              </p>
              <p>
                County: <span>{clientDataById?.NameAndAddress.county}</span>
              </p>
              <p className="text">
                Country: <span>{clientDataById?.NameAndAddress.country}</span>
              </p>
              <p className="text">
                Post code:{" "}
                <span>{clientDataById?.NameAndAddress.postcode}</span>
              </p>
            </div>
          )
        ) : (
          <p>No details available</p>
        )}
      </div>
    </div>
  );
};

export default ContactHistoryDetails;
