import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ClientList from "../pages/client/List";
import ClientLayout from "../layout/index";
import ActionList from "../pages/Actionlist/List";
import Report from "../pages/report";
import SystemManagerLayout from "../pages/systemManager/SystemManagerLayout";
import MasterSettings from "../pages/systemManager/masterSettings";
import { useEffect, useState } from "react";
import ManageUser from "../pages/systemManager/manageUser";
import ProfileSettings from "../pages/ProfileSettings";
import { checkUserPermission } from "../utils";
import { UserPermissionsList } from "../config";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Commission from "../pages/systemManager/commission";
import Utilities from "../pages/utilities/utilities";
import Subscription from "../pages/systemManager/Subscription";
import MasterSttingsPage from "../pages/systemManager/masterSettings/MasterSttingsPage";
import Integrations from "../pages/systemManager/Integrations";
import ContractEnquiry from "../pages/systemManager/ContractEnquiry";
import Templates from "../pages/systemManager/Templates";
import Providers from "../pages/systemManager/Providers/Providers";
import CompanyDetails from "../pages/systemManager/CompanyDetails/CompanyDetails";
import ReportMain from "../pages/report/ReportMain";
import ReportDemo from "../pages/New_report/ReportDemo";
import ProfileRouting from "../pages/ProfileSettings/profileRouting";
import {
  Toast,
  ToastBody,
  Toaster,
  ToastTitle,
  useId,
  useToastController,
} from "@fluentui/react-components";

export const PrivateRoutes = () => {
  const [mode, setMode] = useState("view");
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle>{title ?? title}</ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      {
        intent: type ? type : "success",
        position: "top-end",
        timeout: type && type === "error" ? 20000 : 4000,
      }
    );

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const sideMenuGlobal = {
    actionList: [],
    clients: [],
    reports: [],
    utilities: [],
    systemManager: [
      { label: "Subscription" },
      { label: "Manage Users", link: "/system-manager/manage-user" },
      { label: "Commission", link: "/system-manager/Commission" },
    ],
  };

  if (
    checkUserPermission(
      loggedUser,
      UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
    )
  ) {
    const manageUsersIndex = sideMenuGlobal.systemManager.findIndex(
      (item) => item.label === "Manage Users"
    );
    sideMenuGlobal.systemManager.splice(manageUsersIndex + 1, 0, {
      label: "Master Settings",
      link: "/system-manager",
    });
  }

  const [tabs, setTabs] = useState("");
  const [activeMenu, setActiveMenu] = useState("");
  const [isMaster, setIsMaster] = useState(false);
  useEffect(() => {
    if (
      checkUserPermission(
        loggedUser,
        UserPermissionsList.ALLOW_UPDATE_MASTER_DATA
      )
    ) {
      setIsMaster(true);
      setActiveMenu("Master Settings");
    } else {
      setActiveMenu("Manage Users");
      setIsMaster(false);
    }
  }, [loggedUser]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ClientLayout
            tabs={tabs}
            sideMenuGlobal={sideMenuGlobal}
            activeMenu={activeMenu}
          />
        }
      >
        <Route index element={<ClientList setTabs={setTabs} />} />
        <Route path="/clients" element={<ClientList setTabs={setTabs} />} />
        <Route path="/action-list" element={<ActionList setTabs={setTabs} />} />
        <Route path="/utilities" element={<Utilities setTabs={setTabs} />} />
        {/* <Route path="/report" element={<Report setTabs={setTabs} />} /> */}
        {/* <Route path="/reportdemo" element={<ReportDemo />} /> */}
        <Route path="/report" element={<ReportDemo />} />

        <Route
          path="/commission"
          element={<ClientList setTabs={setTabs} module="commission" />}
        />
        <Route
          path="/system-manager"
          element={<SystemManagerLayout setTabs={setTabs} />}
        >
          <Route path="" element={<CompanyDetails />} />
          <Route path="master-settings" element={<MasterSttingsPage />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="company-details" element={<CompanyDetails />} />
          <Route path="contract-enquiry" element={<ContractEnquiry />} />
          <Route path="providers" element={<Providers />} />
          <Route path="templates/:section" element={<Templates />} />
          <Route
            path="/system-manager/customisations"
            element={<MasterSttingsPage />}
          />
          <Route
            path="customisations/:section"
            element={<MasterSttingsPage />}
          />
          <Route
            path="manage-user"
            element={<ManageUser setActiveMenu={setActiveMenu} />}
          />
          <Route path="commission" element={<Commission />} />
          <Route path="commission/:section" element={<Commission />} />
        </Route>

        <Route
          path="/profile"
          element={
            <ProfileSettings
              setActiveMenu={setActiveMenu}
              mode={mode}
              setMode={setMode}
              notify={notify}
            />
          }
        >
          <Route
            path=":section"
            element={
              <ProfileRouting mode={mode} setMode={setMode} notify={notify} />
            }
          />
          <Route
            index
            element={<Navigate to="/profile/account" replace />}
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
