import Axios from "axios";
import { seesionExpired, accessDenied } from "../redux/appSettingsSlice";
import store from "../redux/store";
import { setCurrentUser } from "../redux/authSlice";
// Create an Axios instance
const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
// const timestamp = Date.now();
let timestamp: any = new Date();
timestamp = timestamp.toISOString();
const generateNonce = () => {
  const CryptoJS = require("crypto-js");
  const requestData = {};
  const hash = CryptoJS.SHA256(`${requestData}${timestamp}`);
  return hash.toString(CryptoJS.enc.Hex);
};

// const generateChecksum = (data: any, nonce: any, timestamp: any) => {
//   const CryptoJS = require("crypto-js");
//   const hash = CryptoJS.SHA256(`${data}${nonce}${timestamp}`);
//   return hash.toString(CryptoJS.enc.Hex);
// };
const nonce = generateNonce();

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: any) => {
    // Modify the request config, e.g., add headers
    const token = store.getState().authUser.userToken;
    const userId = store.getState().authUser.userId;
    const refreshToken = store.getState().authUser.refreshToken;
    // console.log("redux store in axios interceptor",store.getState())

    // const token = localStorage.getItem("assystUserToken");
    // const userId = localStorage.getItem("assystUserId");
    // const refreshToken = localStorage.getItem("assystUserRefreshToken");
    // config.headers["Content-type"] = "application/json";
    if (!(config.data instanceof FormData)) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-Nonce"] = nonce;
    config.headers["X-Timestamp"] = timestamp;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (userId) {
      config.headers.UserId = userId;
    }
    if (refreshToken) {
      config.headers.RefreshToken = refreshToken;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);
// Response interceptor
axiosInstance.interceptors.response.use(
  (response: any) => {
    // Process the response data

    // Access and log the Authorization header
    let authorizationHeader =
      response.headers["authorization"] ||
      response.headers["x-amzn-remapped-authorization"];
    if (
      authorizationHeader !== undefined &&
      authorizationHeader.startsWith("Bearer ")
    ) {
      authorizationHeader = authorizationHeader.replace("Bearer ", "");

      store.dispatch(setCurrentUser(authorizationHeader));
    }
    return response;
  },
  (error: any) => {
    const { config, response } = error;

    if (!error.response) {
      console.log("Network Error:", error.message);
    } else {
      // Handle authentication errors, e.g., refresh token
      if (error.response?.status === 401) {
        // alert("token expired");
        store.dispatch(seesionExpired(true));
        // alert(error.response.data.message);
        // Implement token refresh logic or redirect to login
      } else if (error.response?.status === 403) {
        store.dispatch(accessDenied(true));
      } else {
      }
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
export const axiosPublicInstance = Axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_URL,
});
