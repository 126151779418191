import React, { useEffect, useState } from "react";
import FormContent from "../../../components/Form/FormContent";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { setFormData, resetFormData } from "../../../redux/reducer";
import { formBuilder } from "../../../utils/formGenerator";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import {
  makeStyles,
  shorthands,
  Button,
  tokens,
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Label,
  Card,
  CardHeader,
  CardPreview,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  DismissCircle20Regular,
} from "@fluentui/react-icons";
import axios from "axios";
import apiServices from "../../../service";
import { checkUserPermission } from "../../../utils";
import { UserPermissionsList } from "../../../config";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { FormErrorMessage } from "../../../components/FormErrorMessage/FormErrorMessage";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
  
});
type PartnerFormContainerProps = {
  style?: React.CSSProperties;
  title?: string;
  context: string;
  mode?: string;
  [x: string]: any;
  changeMode?: (mode: string) => void;
  changeTab?: (index: number) => void;
};
const PartnerFormContainer = (props: PartnerFormContainerProps) => {
  let { context } = props;
  const classes = useStyles();
  // const toasterId = useId("toaster");
  // const { dispatchToast } = useToastController(toasterId);
  const [formContext, setFormContext] = useState("");
  const [nextAction, setNextAction] = useState("");
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [formError, setFormError] = useState<any[]>([]);
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [optionData, setOptionData] = useState<any>();
  const [optionList, setOptionList] = useState<any>([]);
  const [isOptionLoaded, setIsOptionLoaded] = useState<boolean>(false);
  const loggedUser = useSelector((state: RootState) => state.authUser);

  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const dispatch: AppDispatch = useDispatch();

  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);

  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    // console.log("field", field);
    // console.log("options", options);
    // console.log("selectFieldOptions", selectFieldOptions);
    // console.log("selectFieldOptionLabels", selectFieldOptionLabels);
    // let fieldInfo = selectFieldOptions.find((e: any) => e.field === field);
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    // console.log("labelInfo", labelInfo);
    // console.log("fieldInfo", fieldInfo);
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
    // console.log("newlyAddedSelectOptions", newlyAddedSelectOptions);
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {
          // console.log("response--add options", response);
        })
        .catch((err) => console.log(err));
    }
  };

  const generateDynamicForm = (formContext: string) => {
    let { initialValues, inputs, validationSchema } = formBuilder({
      context: context,
      formSettings:
        props.module != undefined && props.module == "commission"
          ? objFormSettings.data.commission
          : objFormSettings.data,
    });

    let filteredSelectionOptions = inputs
      .filter(
        (opt: any) =>
          opt.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.listType) &&
          opt.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.fieldOptionLabel,
        listType: opt.listType,
        formField: opt.field,
      }));
    // console.log("filteredSelectionOptions", filteredSelectionOptions);
    setSelectFieldOptionLabels(filteredSelectionOptions);
    if (filteredSelectionOptions.length > 0) {
      getSelectionListOptions(filteredSelectionOptions);
    }
    setFormFieldData({ initialValues, inputs, validationSchema });
    // setPrimaryContact("home_phone");
  };

  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          // console.log("index", index);
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });

        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
            // console.log("response-selectionlist res", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // const response = await axios.get("/option2.json");
      // const optiondata = response.data;
      // if (optiondata.data && optiondata.data.length) {
      //   setSelectFieldOptions(optiondata.data);
      // }

      // console.log("optiondata", optiondata);
      // setOptionData(optiondata);
      // optiondata[context] && setOptionList(optiondata[context]);
      // optionList && setIsOptionLoaded(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  const undo = () => {
    if (past.length === 0) return;

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    formik.setValues(newPresent);
  };

  useEffect(() => {
    if (context !== "") {
      setFormContext(context);
      generateDynamicForm(context);
    }
    const fetchOption = async () => {
      try {
        const response = await axios.get("/option.json");
        const optiondata = response.data;
        setOptionData(optiondata);
        optiondata[context] && setOptionList(optiondata[context]);
        optionList && setIsOptionLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchOption();
  }, [context]);
  // useEffect(() => {
  //   if (formik) {
  //     formik.setValues(present);
  //   }
  // }, [present]);
  // const generateDynamicForm = (formContext: string) => {
  //   let { initialValues, inputs, validationSchema } = formBuilder({
  //     context: context,
  //     formSettings: objFormSettings.data,
  //   });
  //   setFormFieldData({ initialValues, inputs, validationSchema });
  // };

  const formik = useFormik({
    initialValues:
      props.mode && ["edit", "view"].includes(props.mode)
        ? props.initialData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},
    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  useEffect(() => {
    if (props.clientFormSubmited === true && formik) {
      formik.handleSubmit();
    }
  }, [props.clientFormSubmited]);

  const customHandleChange = (event: any) => {
    // alert("custom handle chnage");
    handleOnChange(event);
  };
  const [loading, setLoading] = useState(false);


  const formSubmitted = (values: any) => {
    setIsFormLoading(true);
    const payload = formFieldData.inputs.map(({ field }: any) => field);
    let reqBody: { [key: string]: any } = {};

    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });
    }

    if (props.mode === "add") {
      let addReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        addReq[props.config.renderForm.reference_id] = reqBody;
        addReq[props.config.renderForm.reference_id]["client_value"] =
          "partner";
        addReq[props.config.renderForm.reference_id]["customer_id"] =
          props.activeCustomerId;
      }
      setFormError([]);
      if (props.module != undefined && props.module == "commission") {
        apiServices.commissionModule.commissionClient
          .add(addReq)
          .then((response: any) => {
            if (!response.data.isError) {
              AddSelectOptions();
              setTimeout(() => {
                setIsFormLoading(false);
                props.notify(response.data.message, "", "success");
              }, 1000);
              dispatch(resetFormData());
            }
          })
          .catch((e: Error) => {
            console.log("err");
          });
      } else {
      apiServices.client
        .createNew(addReq)
        .then((response: any) => {
          if (!response.data.isError) {
            AddSelectOptions();
            setTimeout(() => {
              setIsFormLoading(false);
              props.notify(response.data.message, "", "success");
            }, 1000);
            // props.setFormUpdated(false);
            dispatch(resetFormData());
            props.getClientDetail(response.data.data.NameAndAddress.associated_with);
            if (props.setActivePartnerId) {
              props.setActivePartnerId(response.data.data.customer_id);
            }
            if (props.setPartnerFormMode !== undefined) {
              props.setPartnerFormMode("edit");
            }
            // if (props.changeMode) {
            //   // props.setActiveItem(response.data.data);
            //   props.changeMode("edit");
            // }
            if (nextAction === "next") {
              props.changeTab?.(props.tabMenuIndex.next);
            }
          } else if (response.data.isError) {
            if (
              response.data.error &&
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              if (Array.isArray(response.data.error.message)) {
                setFormError(response.data.error.message);
              } else {
                setFormError((prev) => [response.data.error.message]);
              }
            }
          } else {
            props.notify("Something went wrong", "", "error");
          }
        })
        .catch((e: Error) => {
          props.notify(e.message, "", "error");
        });
      }
    } else if (props.mode === "edit") {
      let updateReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.module != undefined && props.module == "commission") {
          updateReq[props.config.renderForm.reference_id] = reqBody;
        } else{
          updateReq[props.config.renderForm.reference_id] = reqBody;
          updateReq[props.config.renderForm.reference_id]["client_value"] =
            "partner";
          updateReq[props.config.renderForm.reference_id]["customer_id"] =
            props.activeCustomerId;
        }
      }
      setFormError([]);
      if (props.module != undefined && props.module == "commission") {
        apiServices.commissionModule.commissionClient
        .update(props.activeCustomerId, updateReq)
        .then((response: any) => {
          if (!response.data.isError) {
            AddSelectOptions();
            setTimeout(() => {
              setIsFormLoading(false);
              props.notify(response.data.message, "", "success");
            }, 1000);
            dispatch(resetFormData());
          }
        })
        .catch((e: Error) => {
          console.log("err");
        });
      }else{
      apiServices.client
        .updateClient(props.initialData.customer_id, updateReq)
        .then((response: any) => {
          if (!response.data.isError) {
            AddSelectOptions();
            setTimeout(() => {
              setIsFormLoading(false);
              props.notify(response.data.message, "", "success");
            }, 1000);
            // props.setFormUpdated(false);
            dispatch(resetFormData());
            // props.getList();
            // if (props.resetClientFormSubmited) {
            //   props.resetClientFormSubmited();
            // }
            if (props.changeMode) {
              // props.setActiveItem(response.data.data);
              props.changeMode("edit");
            }
            if (nextAction === "next") {
              props.changeTab?.(props.tabMenuIndex.next);
            }
          } else if (response.data.isError) {
            if (
              response.data.error &&
              response.data.error &&
              Object.keys(response.data.error).length !== 0
            ) {
              if (Array.isArray(response.data.error.message)) {
                setFormError(response.data.error.message);
              } else {
                setFormError((prev) => [response.data.error.message]);
              }
            }
          } else {
            props.notify("Something went wrong", "", "error");
          }
        })
        .catch((e: Error) => {
          props.notify(e.message, "", "error");
        });
    }
  }
  };

  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;
    // dispatch(setFormData());

    // if (["title", "first_name", "last_name"].includes(event.target.name)) {
    //   formik.setFieldValue("salutation", getSalutations(data));
    // }
    updatePresent(data);
    // if (isFormDataUpdated) {
    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      updated = false;
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
      updated = true;
    }
    // }

    if (props.mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(props.mode === "add" ? "New" : "");
    }
  };
  
  return (
    <>
      {props.mode && ["edit", "add"].includes(props.mode) && (
        <div className={classes.btnwrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label size="medium" weight="semibold" className={classes.message}>
              {formMode !== "" && (
                <>
                  <span>**</span> {formMode} <span>**</span>
                </>
              )}
            </Label>
          </div>

          <div>
            <Button
              icon={<ArrowUndo16Regular />}
              onClick={undo}
              disabled={!isFormDataUpdated}
            ></Button>
            <Button
              icon={<ArrowRedo16Regular />}
              onClick={redo}
              disabled={future.length === 0 ? true : false}
            ></Button>
          </div>
          <Button
            disabled={!isFormDataUpdated}
            appearance="transparent"
            onClick={() => {
              formik.resetForm();
            }}
          >
            Reset All
          </Button>
        </div>
      )}
      {props.mode && props.mode === "view" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) && (
            <Button
              style={{ marginRight: 2, marginBottom: 6 }}
              icon={<Edit24Filled />}
              onClick={() => {
                if (props.changeMode) {
                  props.changeMode("edit");
                }
                // setFormMode("edit");
              }}
            >
              Edit
            </Button>
          )}
        </div>
      )}

      {formError && formError.length > 0 && (
        <FormErrorMessage
          errorMessages={formError}
          onDismiss={() => setFormError([])}
        />
      )}
      <div style={{ height: "calc(100vh - 380px)", overflow: "auto" }}>
      {isOptionLoaded && (
        <FormContent
          {...{
            formik,
            formFieldData,
            mode: props.mode,
            handleOnChange,
            optionList,
            customHandleChange,
            selectFieldOptions,
            newSelectOptionAdded,
          }}
        />
      )}
      {props.mode !== "view" && isOptionLoaded && (
        <div className={classes.buttonWrapper}>
          <Button
            className="asc-button-primary"
            appearance="primary"
            // disabled={formik.isSubmitting ? true : false}
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              isFormDataUpdated ? formik.handleSubmit() : "";
            }}
            disabled={isFormLoading}
          >
            Save
          </Button>
          {props.tabMenuIndex && props.tabMenuIndex.next !== -1 && (
            <Button
              onClick={() => {
                setNextAction("next");
                formik.handleSubmit();
              }}
              disabled={isFormLoading || !isFormDataUpdated}
            >
              Save & Next
            </Button>
          )}

          <Button
            onClick={() => {
              // props.formDataUpdated
              isFormDataUpdated
                ? dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1:
                        "We have detected an attempt to move away from the current page.",
                      content_line_2:
                        "This would cause all changes made to be lost",
                      cancel: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                          dispatch(resetFormData());
                          props.closePage();
                        },
                        label: "Leave the Page",
                      },
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Stay on Page",
                        type: "primary",
                      },
                    })
                  )
                : props.closePage();
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      </div>
    </>
  );
};

export default PartnerFormContainer;
