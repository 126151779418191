import { Dispatch, SetStateAction, useEffect } from "react";
import UserManagementForm from "./UserManagementForm";
import ModalDialog from "../../../components/ModalDialog";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import {
  useId,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  Link,
  ToastBody,
  ToastTrigger,
} from "@fluentui/react-components";
interface PropsType {
  setActiveMenu: Dispatch<SetStateAction<string>>;
}

const ManageUser = ({ setActiveMenu }: PropsType) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );
  useEffect(() => {
    setActiveMenu("Manage Users");
  }, []);
  return (
    <div>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <UserManagementForm notify={notify} />
      <ModalDialog options={dialogModalOptions} />
    </div>
  );
};

export default ManageUser;
