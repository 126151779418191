import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Select,
  useId,
  makeStyles,
  Button,
  Divider,
  tokens,
  CompoundButton,
  ButtonProps,
  Tab,
  TabList,
  shorthands,
  Label,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Radio,
  RadioOnChangeData,
  RadioGroup,
} from "@fluentui/react-components";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbProps,
  BreadcrumbButton,
  BreadcrumbItemProps,
} from "@fluentui/react-breadcrumb-preview";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  DrawerProps,
} from "@fluentui/react-components";
import {
  Add20Filled,
  Add16Filled,
  ChevronLeft20Regular,
  Add20Regular,
  DocumentBulletList20Regular,
  DocumentFolder20Regular,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import FormContainer from "./FormContainer";
import { TableSmall } from "../../../components/TableSmall";
import LiabilityDetails from "./LiabilityDetails";
import {
  camalize,
  checkIfKeyExist,
  checkUserPermission,
  convertStringToArray,
  formatedDate,
  formatedDate2,
  getAssetCategoryByCaseType,
  getClientType,
  getYes_NoConversion,
} from "../../../utils";
import LiabilityListItems from "./LiabilityListItems";
import FormContainerDetailPage from "./FormContainerDetailPage";
import { addBreadcrumbs } from "../../../redux/appSettingsSlice";
import apiServices from "../../../service";
import "../style.css";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import LoadStandardAction from "../../../components/LoadStandardAction";
import { UserPermissionsList } from "../../../config";
import { PageLoader } from "../../../components/Loader/PageLoader";
import OverlayLoader from "../../../components/OverlayLoader";
import {
  CreditCards,
  LoansLeaseHP,
  Mortgages,
} from "../../../shared/LiabilityDetails";

const renderDate = (date: string) => (date ? formatedDate(date) : "-");
const renderRichText = (text: string) =>
  text ? <ReactQuillComponent value={text} /> : "-";
const renderYesorNo = (value: number) =>
  value ? getYes_NoConversion(value) : "-";
const renderclient = (value: number) => (value ? getClientType(value) : "-");
const CaseDetails = (activeItem: any) => {
  if (Object.keys(activeItem).length !== 0) {
    if (activeItem.case_type === "Mortgages") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            Mortgages.length > 0 &&
            Mortgages.map((Mortgages: any) => (
              <div key={Mortgages.Mortgages_id} className="details">
                <p className="details_label">{Mortgages.label} </p>
                <p>
                  {(() => {
                    switch (Mortgages.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";
                      case "mortgage_link":
                        return renderYesorNo(activeItem.mortgage_link);
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "amount_outstanding_date":
                        return renderDate(activeItem.amount_outstanding_date);

                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);

                      case "admin_note":
                        return renderRichText(activeItem.admin_note);

                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[Mortgages.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Loans/Lease/HP") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            LoansLeaseHP.length > 0 &&
            LoansLeaseHP.map((Loans: any) => (
              <div key={Loans.LoansLeaseHP_id} className="details">
                <p className="details_label">{Loans.label} </p>
                <p>
                  {(() => {
                    switch (Loans.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "amount_outstanding_date":
                        return renderDate(activeItem.amount_outstanding_date);

                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);

                      case "admin_note":
                        return renderRichText(activeItem.admin_note);

                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[Loans.value];
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    } else if (activeItem.case_type === "Credit Cards") {
      return (
        <div>
          {Object.keys(activeItem).length !== 0 &&
            CreditCards.length > 0 &&
            CreditCards.map((LiabilityCreditCards: any) => (
              <div
                key={LiabilityCreditCards.CreditCards_id}
                className="details"
              >
                <p className="details_label">{LiabilityCreditCards.label} </p>
                <p>
                  {(() => {
                    switch (LiabilityCreditCards.value) {
                      case "joint_indicator":
                        return getClientType(activeItem.joint_indicator) || "-";
                      case "start_date":
                        return renderDate(activeItem.start_date);
                      case "end_date":
                        return renderDate(activeItem.end_date);
                      case "amount_outstanding_date":
                        return renderDate(activeItem.amount_outstanding_date);

                      case "provider_note":
                        return renderRichText(activeItem.provider_note);
                      case "benefit_text":
                        return renderRichText(activeItem.benefit_text);

                      case "admin_note":
                        return renderRichText(activeItem.admin_note);

                      case "report_note":
                        return renderRichText(activeItem.report_note);
                      default:
                        return activeItem[LiabilityCreditCards.value] || "-";
                    }
                  })()}
                </p>
              </div>
            ))}
        </div>
      );
    }
  } else {
    return <p>No details available</p>;
  }
};

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    alignItems: "flex-end",
    marginBottom: "10px",
  },
  btnWrapper: {
    columnGap: "15px",
    display: "flex",
    marginTop: "20px",
    marginBottom: "10px",
  },
  selectBoxLabel: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  tabList: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("10px", "0px"),
    rowGap: "20px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalS,
    marginBottom: "20px",
  },
});

type componentProps = {
  [x: string]: any;
};
const LiabilityForm = (props: componentProps) => {
  const selectId = useId();
  const styles = useStyles();

  const [liabilityType, setLiabilityType] = useState("");
  const [liabilityList, setLiabilityList] = useState([]);
  const [defaultAction, setDefaultAction] = useState(false);
  const [liabilityActiveTabLevel1, setLiabilityActiveTabLevel1] =
    useState("liability");
  const [liabilityActiveTabLevel2, setLiabilityActiveTabLevel2] = useState("");
  const [currentPage, setCurrentPage] = useState("listing");
  const [formContext, setFormContext] = useState("");
  const [detailFormContext, setDetailFormContext] = useState("");
  const [mode, setMode] = useState("");
  const [paymentValue, setPaymentValue] = useState<any>();
  const [detailPageMode, setDetailPageMode] = useState("");
  const [initialFormData, setInitialFormData] = useState({});
  const [liabilityRowSelection, setLiabilityRowSelection] = React.useState({});

  const [activeItem, setActiveItem] = useState<any | {}>({});
  const [activeLiabilityTab, setActiveLiabilityTab] = useState("liability");

  const dispatch: AppDispatch = useDispatch();

  const [liabilitySubitemsList, setLiabilitySubitemsList] = useState<any>([]);
  const [liabilitySubActiveItem, setLiabilitySubActiveItem] = useState<any>({});
  const [detailPageSelectedRowIndex, setDetailPageSelectedRowIndex] =
    useState(0);
  const [initialDetailPageFormData, setInitialDetailPageFormData] = useState(
    {}
  );
  const [currentRecord, setCurrentRecord] = useState(1);
  const [currentSubRecord, setCurrentSubRecord] = useState(1);
  const [isOpen, setIsOpen] = React.useState(false);

  const [liabilityCatPopup, setLiabilityCatPopup] = React.useState(false);
  const [selectedLiabilityCatPopup, setSelectedLiabilityCatPopup] =
    React.useState("");

  const [liabilityActiveTabLevel2Subtab, setLiabilityActiveTabLevel2Subtab] =
    useState("");

  const loggedUser = useSelector((state: RootState) => state.authUser);
  const radioName = useId("radio");
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);

  const labelId = useId("label");
  const [loadStandardActionPopup, setLoadStandardActionPopup] = useState(false);
  const [loadStandardActionDate, setLoadStandardActionDate] = useState(
    new Date()
  );
  const [selectedLoadStandardActions, setSelectedLoadStandardActions] =
    useState<any>([]);
  const [activePaymentItem, setActivePaymentItem] = useState<any>({});
  const [activeProvider, setActiveProvider] = useState<any>({});
  const [activeLiabilityDetail, setActiveLiabilityDetail] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const [subItemLoading, setSubItemLoading] = useState(false);

  const contextList: any = {
    Mortgages: {
      liability: "LIABILITIES_MORTGAGES_LIABILITY",
      review: "LIABILITIES_MORTGAGES_REVIEW",
      payment: "LIABILITIES_MORTGAGES_PAYMENT",
      paymentcomplaince: "LIABILITIES_MORTGAGES_PAYMENT_COMPLAINCE",
      paymentCommission: "LIABILITIES_MORTGAGES_PAYMENT_COMMISSION",
      actions: "LIABILITIES_MORTGAGES_ACTIONS",
    },
    "Loans/Lease/HP": {
      liability: "LIABILITIES_LOAN_HIRE_PURCHASE_LIABILITY",
      review: "LIABILITIES_LOAN_HIRE_PURCHASE_REVIEW",
      payment: "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENT",
      paymentcomplaince: "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENTS_COMPLAINCE",
      paymentCommission: "LIABILITIES_LOAN_HIRE_PURCHASE_PAYMENTS_COMMISSION",
      actions: "LIABILITIES_LOAN_HIRE_PURCHASE_ACTIONS",
    },
    "Credit Cards": {
      liability: "LIABILITIES_CREDIT_CARDS_LIABILITY",
      review: "LIABILITIES_CREDIT_CARDS_REVIEW",
      payment: "LIABILITIES_CREDIT_CARDS_PAYMENTS",
      paymentcomplaince: "LIABILITIES_CREDIT_CARDS_PAYMENTS_COMPLAINCE",
      paymentCommission: "LIABILITIES_CREDIT_CARDS_PAYMENTS_COMMISSION",
    },
  };

  const handleBreadcrumbs = () => {
    let breadcrumbsItems: { key: number; value: string; link?: string }[] = [
      {
        key: 0,
        value: "Home",
        link: "/",
      },
      {
        key: 1,
        value: "Clients",
        link: "/clients",
      },
      {
        key: 2,
        value: "Client",
        link: "/client",
      },
      {
        key: 3,
        value: "Liability",
        link: "/client",
      },
    ];

    if (liabilityType !== "" && liabilityList.length > 0) {
      breadcrumbsItems.push({
        key: 4,
        value: camalize(liabilityType),
      });
    }
    dispatch(addBreadcrumbs(breadcrumbsItems));
    // }
  };

  const getContextDetails = (liabilityType: string, context: string) => {
    if (contextList[liabilityType] && contextList[liabilityType][context]) {
      setFormContext(contextList[liabilityType][context]);
    } else {
      setFormContext("");
    }
  };

  useEffect(() => {
    setLiabilitySubitemsList([]);
  }, [liabilityActiveTabLevel2Subtab]);
  useEffect(() => {
    fetchLiabilityLists();
    // handleBreadcrumbs();
  }, []);

  useEffect(() => {
    if (liabilityList.length > 0) {
      selectedIndex(0);
    }
  }, [liabilityList]);

  useEffect(() => {
    if (liabilitySubitemsList.length > 0) {
      selectedSubIndex(0);
    } else {
      setDetailFormContext("");
    }
  }, [liabilitySubitemsList]);

  // useEffect(() => {
  //   getLiabilitySubItems();
  //   setDetailFormContext("");
  //   handleBreadcrumbs();
  // }, [liabilityActiveTabLevel2]);

  // useEffect(()=>{
  //   if (liabilityActiveTabLevel2 === "payment") {
  //       setPaymentData((liabilitySubActiveItem as { [key: string]: any }).payment.payment_id)
  //   }

  // },[liabilityActiveTabLevel2])

  useEffect(() => {
    if (liabilityActiveTabLevel2 !== "") {
      if (liabilityActiveTabLevel2 === "payment") {
        setLiabilityActiveTabLevel2Subtab("payment");
      } else {
        setLiabilityActiveTabLevel2Subtab("");
      }
      // else {
      //   getLiabilitySubItems();
      // }
    }
    getLiabilitySubItems();
    setDetailFormContext("");
    handleBreadcrumbs();
  }, [liabilityActiveTabLevel2]);

  useEffect(() => {
    if (liabilityActiveTabLevel2Subtab !== "") {
      getLiabilitySubItems();
    }
  }, [liabilityActiveTabLevel2Subtab]);

  useEffect(() => {
    if (liabilityList.length > 0) {
      let index = 0;
      index = liabilityList.findIndex(
        (x: any) => x.liability_id === activeItem.liability_id
      );
      if (activeLiabilityTab === "liability") {
        setInitialFormData(setLiabilityFieldValues(liabilityList[index]));
      } else if (activeLiabilityTab === "review") {
        setInitialFormData(setLiabilityReviewFieldValues(liabilityList[index]));
      } else {
      }
    }
  }, [mode]);

  useEffect(() => {
    // setMode(detailPageMode);

    if (liabilitySubitemsList.length > 0) {
      let index = 0;

      if (liabilityActiveTabLevel2 === "payment") {
        if (liabilityActiveTabLevel2Subtab === "paymentCommission") {
          index = liabilitySubitemsList.findIndex(
            (x: any) => x.commission_id === liabilitySubActiveItem.commission_id
          );
        } else {
          index = liabilitySubitemsList.findIndex(
            (x: any) =>
              x.payment.payment_id === liabilitySubActiveItem.payment.payment_id
          );

          if (liabilityActiveTabLevel2Subtab === "payment") {
            setInitialDetailPageFormData(
              setPaymentFieldValues(liabilitySubitemsList[index])
            );
          } else if (liabilityActiveTabLevel2Subtab === "paymentcomplaince") {
            setInitialDetailPageFormData(
              setPaymentComplainceFieldValues(liabilitySubitemsList[index])
            );
          } else {
          }
        }
      }
      // else if (liabilityActiveTabLevel2 === "withdrawals") {
      //   index = liabilitySubitemsList.findIndex(
      //     (x: any) => x.withdrawal_id === liabilitySubActiveItem.withdrawal_id
      //   );
      //   setInitialDetailPageFormData(
      //     setWithdrawalsFieldValues(liabilitySubitemsList[index])
      //   );
      // }
      // else if (liabilityActiveTabLevel2 === "valuations") {
      //   index = liabilitySubitemsList.findIndex(
      //     (x: any) => x.valuation_id === liabilitySubActiveItem.valuation_id
      //   );
      //   setInitialDetailPageFormData(
      //     setValuationsFieldValues(liabilitySubitemsList[index])
      //   );
      // }
      else if (liabilityActiveTabLevel2 === "actions") {
        index = liabilitySubitemsList.findIndex(
          (x: any) => x.business_id === liabilitySubActiveItem.business_id
        );
        setInitialDetailPageFormData(
          setActionsFieldValues(liabilitySubitemsList[index])
        );
      }
      //  else if (liabilityActiveTabLevel2 === "fund") {
      //   index = liabilitySubitemsList.findIndex(
      //     (x: any) => x.fund_id === liabilitySubActiveItem.fund_id
      //   );
      //   setInitialDetailPageFormData(
      //     setFundFieldValues(liabilitySubitemsList[index])
      //   );
      // }
      else {
      }
    }
  }, [detailPageMode]);

  useEffect(() => {
    // alert("activeLiabilityTab==" + currentRecord);
    if (activeLiabilityTab === "review" && currentRecord) {
      setInitialFormData(
        setLiabilityReviewFieldValues(liabilityList[currentRecord - 1])
      );
    } else if (activeLiabilityTab === "liability" && currentRecord) {
      setInitialFormData(
        setLiabilityFieldValues(liabilityList[currentRecord - 1])
      );
    } else {
    }
    getContextDetails(liabilityType, activeLiabilityTab);
  }, [activeLiabilityTab]);

  const showReviewForm = () => {
    // getContextDetails(liabilityType, "review");
    // alert(liabilityType);
  };

  useEffect(() => {
    // if (liabilityList.length === 0 && liabilityType !== "") {
    //   setDefaultAction(true);
    //   getContextDetails(liabilityType, liabilityActiveTabLevel1);
    // } else {
    //   setDefaultAction(false);
    // }

    handleBreadcrumbs();
  }, [liabilityType]);

  useEffect(() => {
    // console.log("activeItem--->>>", activeItem);
    if (Object.keys(activeItem).length !== 0) {
      getLiabilityDetails(activeItem.customer_id, activeItem.liability_id);
    }
  }, [activeItem]);

  useEffect(() => {
    if (Object.keys(activeLiabilityDetail).length !== 0) {
      if (activeLiabilityTab === "liability") {
        setInitialFormData(setLiabilityFieldValues(activeLiabilityDetail));
      } else if (activeLiabilityTab === "review") {
        setInitialFormData(
          setLiabilityReviewFieldValues(activeLiabilityDetail)
        );
      } else {
      }
    }
  }, [activeLiabilityDetail]);

  const getLiabilityDetails = (customerId: string, LiabilityId: string) => {
    setLoading(true);
    apiServices.liability
      .getDetail(LiabilityId, customerId)
      .then((response) => {
        setLoading(false);
        // console.log("liability detail", response);
        if (response.data) {
          if (response.data.data) {
            setActiveLiabilityDetail(response.data.data);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err");
      });
  };

  const fetchLiabilityLists = () => {
    setLoading(true);
    apiServices.liability
      .getLiabilities(props.activeCustomerId)
      .then((res) => {
        setLoading(false);
        if (res.data !== undefined) {
          if (res.data.data) {
            setLiabilityList(res.data.data);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const selectedIndex = (index: number) => {
    if (liabilityList.length > 0) {
      setActiveItem(liabilityList[index]);
      setActiveLiabilityTab(activeLiabilityTab);
      setMode("view");
      if (activeLiabilityTab === "liability") {
        setInitialFormData(setLiabilityFieldValues(liabilityList[index]));
      } else if (activeLiabilityTab === "review") {
        setInitialFormData(setLiabilityReviewFieldValues(liabilityList[index]));
      } else {
      }

      if ((liabilityList as { [key: string]: any })[index].case_type) {
        let case_type_value = (liabilityList as { [key: string]: any })[index]
          .case_type;
        setFormContext(
          (contextList as { [key: string]: any })[case_type_value]["liability"]
        );

        setLiabilityType(case_type_value);
        getContextDetails(case_type_value, liabilityActiveTabLevel1);
      }
      setLoading(true);
      apiServices.liability
        .getDetail(
          (liabilityList as { [key: string]: any })[index].liability_id,
          props.activeCustomerId
        )
        .then((response) => {
          setLoading(true);
          if (response.data) {
            if (response.data.data) {
              setActiveProvider(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err");
        });
    }
  };
  const selectedSubIndex = (index: number) => {
    if (index !== undefined) {
      setLiabilitySubActiveItem(liabilitySubitemsList[index]);

      if (liabilityActiveTabLevel2 === "payment") {
        if (liabilityActiveTabLevel2Subtab === "payment") {
          setInitialDetailPageFormData(
            setPaymentFieldValues(liabilitySubitemsList[index])
          );
          setActivePaymentItem(liabilitySubitemsList[index]);
        } else if (liabilityActiveTabLevel2Subtab === "paymentcomplaince") {
          setInitialDetailPageFormData(
            setPaymentComplainceFieldValues(liabilitySubitemsList[index])
          );
        } else if (liabilityActiveTabLevel2Subtab === "paymentCommission") {
          setInitialDetailPageFormData(
            setcommissionFieldValues(liabilitySubitemsList[index])
          );
        } else {
        }
      }
      // else if (liabilityActiveTabLevel2 === "withdrawals") {
      //   setActivePaymentItem({});
      //   setInitialDetailPageFormData(
      //     setWithdrawalsFieldValues(liabilitySubitemsList[index])
      //   );
      // }
      // else if (liabilityActiveTabLevel2 === "valuations") {
      //   setActivePaymentItem({});
      //   setInitialDetailPageFormData(
      //     setValuationsFieldValues(liabilitySubitemsList[index])
      //   );
      // }
      else if (liabilityActiveTabLevel2 === "actions") {
        setActivePaymentItem({});
        setInitialDetailPageFormData(
          setActionsFieldValues(liabilitySubitemsList[index])
        );
      }
      // else if (liabilityActiveTabLevel2 === "fund") {
      //   setActivePaymentItem({});
      //   setInitialDetailPageFormData(
      //     setFundFieldValues(liabilitySubitemsList[index])
      //   );
      // }
      else {
      }

      setDetailPageMode("view");
      if (liabilityActiveTabLevel2 === "payment") {
        setDetailFormContext(
          contextList[liabilityType][liabilityActiveTabLevel2Subtab]
        );
      } else {
        setDetailFormContext(
          contextList[liabilityType][liabilityActiveTabLevel2]
        );
      }
      handleBreadcrumbs();
    }
  };
  const selectActiveLiabilityItem = (item: string) => {
    // setDetailFormContext(contextList[liabilityType][item]);
    setCurrentPage("detail");
    setLiabilityActiveTabLevel2(item);
    // getContextDetails(liabilityType, item);
  };
  const getLiabilitySubItems = () => {
    if (liabilityActiveTabLevel2 != "") {
      // api call
      fetchSubItemList();
    }
  };
  const handleRowAction = (mode: string, row: any) => {
    setActiveItem(row.orginal);
    setMode(mode);
  };
  const handleRowSelection = (liabilityId: any, mode: string) => {
    let liabilityObj = liabilityList.find(
      (item: any) => item.liability_id === liabilityId
    );
    if (liabilityObj) {
      setActiveItem(liabilityObj);
      setMode("view");
      setActiveLiabilityTab(activeLiabilityTab);
      if (activeLiabilityTab === "liability") {
        setInitialFormData(setLiabilityFieldValues(liabilityObj));
      } else if (activeLiabilityTab === "review") {
        setInitialFormData(setLiabilityReviewFieldValues(liabilityObj));
      } else {
      }
      // setInitialFormData(setLiabilityFieldValues(liabilityObj));
      let case_type_value = (liabilityObj as { [key: string]: any }).case_type;

      setFormContext(case_type_value[activeLiabilityTab]);
      setLiabilityType(case_type_value);
      getContextDetails(case_type_value, activeLiabilityTab);
    }
  };
  const handleDetailPageRowSelection = (index: number) => {
    // setDetailPageSelectedRowIndex(index);
    selectedSubIndex(index);
    // alert(index);
    //  let liabilityObj = liabilityList.find((item: any) => item.LiabilityId === liabilityId);
    //  if (liabilityObj) {
    //    setActiveItem(liabilityObj);
    //    setMode("view");
    //    setInitialFormData(setLiabilityFieldValues(liabilityObj));
    //    let case_type_value = (liabilityObj as { [key: string]: any }).CaseType;

    //    setFormContext(case_type_value["liability"]);
    //    setLiabilityType(case_type_value);
    //    getContextDetails(case_type_value, liabilityActiveTabLevel1);
    //  }
  };
  const handleDetailFormContext = (module: string) => {
    setDetailFormContext(contextList[liabilityType][module]);
  };
  const handleReloadList = () => {
    fetchLiabilityLists();
  };
  const handleReloadSublist = () => {
    fetchSubItemList();
  };
  const fetchSubItemList = () => {
    setLoading(true);
    if (liabilityActiveTabLevel2 === "payment") {
      if (liabilityActiveTabLevel2Subtab === "paymentCommission") {
        if (Object.keys(activePaymentItem).length !== 0) {
          apiServices.commission
            .getCommission(
              (activePaymentItem as { [key: string]: any }).payment?.payment_id,
              props.activeCustomerId,
              (activeItem as { [key: string]: any }).liability_id
            )
            .then((response) => {
              setLoading(false);
              if (response.data) {
                if (response.data.data) {
                  setLiabilitySubitemsList(response.data.data);
                }
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      } else {
        apiServices.liability
          .getPayments(
            (activeItem as { [key: string]: any }).liability_id,
            props.activeCustomerId
          )
          .then((response) => {
            setLoading(false);
            if (response.data) {
              if (response.data.data) {
                if (response.data.data && response.data.data.result) {
                  setLiabilitySubitemsList(response.data.data?.result || []);
                  setPaymentValue(response.data.data?.total);
                }
              }
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    } else if (liabilityActiveTabLevel2 === "actions") {
      apiServices.liability
        .getActions(
          (activeItem as { [key: string]: any }).liability_id,
          props.activeCustomerId
        )
        .then((response) => {
          setLoading(false);
          if (response.data) {
            if (response.data.data) {
              setLiabilitySubitemsList(response.data.data);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
    }
  };
  const setLiabilityFieldValues = (params: any) => {
    let paramVal = {};
    if (params?.case_type === "Mortgages") {
      paramVal = {
        ...paramVal,

        joint_indicator:
          mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,

        borrower: params.borrower ? params.borrower : "",
        borrower_name1: params.borrower_name1 ? params.borrower_name1 : "",
        borrower_name2: params.borrower_name2 ? params.borrower_name2 : "",
        liability_type: params.liability_type ? params.liability_type : "",
        subtype: params.subtype ? params.subtype : "",
        repayment_method: params.repayment_method
          ? params.repayment_method
          : "",
        purpose: params.purpose ? params.purpose : "",
        provider: params.provider ? params.provider : "",
        provider_id: params.provider_id ? params.provider_id : "",
        account_number: params.account_number ? params.account_number : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        amount_borrowed: params.amount_borrowed ? params.amount_borrowed : "",
        amount_outstanding: params.amount_outstanding
          ? params.amount_outstanding
          : "",

        amount_outstanding_date: params.amount_outstanding_date
          ? params.amount_outstanding_date
          : "",
        interest_rate: params.interest_rate ? params.interest_rate : "",
        covered: params.covered ? params.covered : "",
        interest_basis: params.interest_basis ? params.interest_basis : "",
        mortgage_fixed_rate_end: params.mortgage_fixed_rate_end
          ? params.mortgage_fixed_rate_end
          : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        property_value: params.property_value ? params.property_value : "",
        loan_valuation_percentage: params.loan_valuation_percentage
          ? params.loan_valuation_percentage
          : "",

        property_address1: params.property_address1
          ? params.property_address1
          : "",
        property_address2: params.property_address2
          ? params.property_address2
          : "",
        property_address3: params.property_address3
          ? params.property_address3
          : "",
        property_town: params.property_town ? params.property_town : "",
        property_county: params.property_county ? params.property_county : "",
        property_country: params.property_country
          ? params.property_country
          : "",
        property_postcode: params.property_postcode
          ? params.property_postcode
          : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_correspondence: params.provider_correspondence
          ? activeLiabilityDetail?.provider_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_address1: params.provider_address1
          ? params.provider_address1
          : "",
        provider_address2: params.provider_address2
          ? params.provider_address2
          : "",
        provider_address3: params.provider_address3
          ? params.provider_address3
          : "",
        provider_town: params.provider_town ? params.provider_town : "",
        provider_county: params.provider_county ? params.provider_county : "",
        provider_country: params.provider_country
          ? params.provider_country
          : "",
        provider_postcode: params.provider_postcode
          ? params.provider_postcode
          : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
      // console.log("paramValparamVal", paramVal);
      // console.log("paramsparams", params);
      // console.log("activeLiabilityDetail", activeLiabilityDetail);
    } else if (params?.case_type === "Loans/Lease/HP") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,

        borrower: params.borrower ? params.borrower : "",
        borrower_name1: params.borrower_name1 ? params.borrower_name1 : "",
        borrower_name2: params.borrower_name2 ? params.borrower_name2 : "",
        liability_type: params.liability_type ? params.liability_type : "",
        subtype: params.subtype ? params.subtype : "",
        repayment_method: params.repayment_method
          ? params.repayment_method
          : "",
        purpose: params.purpose ? params.purpose : "",
        provider: params.provider ? params.provider : "",
        provider_id: params.provider_id ? params.provider_id : "",
        account_number: params.account_number ? params.account_number : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        amount_borrowed: params.amount_borrowed ? params.amount_borrowed : "",
        amount_outstanding: params.amount_outstanding
          ? params.amount_outstanding
          : "",

        amount_outstanding_date: params.amount_outstanding_date
          ? params.amount_outstanding_date
          : "",
        interest_rate: params.interest_rate ? params.interest_rate : "",
        interest_basis: params.interest_basis ? params.interest_basis : "",
        mortgage_fixed_rate_end: params.mortgage_fixed_rate_end
          ? params.mortgage_fixed_rate_end
          : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        covered: params.covered ? params.covered : "",

        property_value: params.property_value ? params.property_value : "",
        loan_valuation_percentage: params.loan_valuation_percentage
          ? params.loan_valuation_percentage
          : "",

        property_address1: params.property_address1
          ? params.property_address1
          : "",
        property_address2: params.property_address2
          ? params.property_address2
          : "",
        property_address3: params.property_address3
          ? params.property_address3
          : "",
        property_town: params.property_town ? params.property_town : "",
        property_county: params.property_county ? params.property_county : "",
        property_country: params.property_country
          ? params.property_country
          : "",
        property_postcode: params.property_postcode
          ? params.property_postcode
          : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_address1: params.provider_address1
          ? params.provider_address1
          : "",
        provider_address2: params.provider_address2
          ? params.provider_address2
          : "",
        provider_address3: params.provider_address3
          ? params.provider_address3
          : "",
        provider_town: params.provider_town ? params.provider_town : "",
        provider_county: params.provider_county ? params.provider_county : "",
        provider_country: params.provider_country
          ? params.provider_country
          : "",
        provider_postcode: params.provider_postcode
          ? params.provider_postcode
          : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    } else if (params?.case_type === "Credit Cards") {
      paramVal = {
        ...paramVal,
        joint_indicator:
          mode === "view"
            ? getClientType(params.joint_indicator)
            : params.joint_indicator,
        borrower: params.borrower ? params.borrower : "",
        borrower_name1: params.borrower_name1 ? params.borrower_name1 : "",
        borrower_name2: params.borrower_name2 ? params.borrower_name2 : "",
        liability_type: params.liability_type ? params.liability_type : "",
        subtype: params.subtype ? params.subtype : "",
        repayment_method: params.repayment_method
          ? params.repayment_method
          : "",
        purpose: params.purpose ? params.purpose : "",
        provider: params.provider ? params.provider : "",
        provider_id: params.provider_id ? params.provider_id : "",
        account_number: params.account_number ? params.account_number : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        term: params.term ? params.term : "",
        amount_borrowed: params.amount_borrowed ? params.amount_borrowed : "",
        amount_outstanding: params.amount_outstanding
          ? params.amount_outstanding
          : "",

        amount_outstanding_date: params.amount_outstanding_date
          ? params.amount_outstanding_date
          : "",
        interest_rate: params.interest_rate ? params.interest_rate : "",
        interest_basis: params.interest_basis ? params.interest_basis : "",
        mortgage_fixed_rate_end: params.mortgage_fixed_rate_end
          ? params.mortgage_fixed_rate_end
          : "",
        status: params.status ? params.status : "",
        status_date: params.status_date ? params.status_date : "",
        covered: params.covered ? params.covered : "",

        property_value: params.property_value ? params.property_value : "",
        loan_valuation_percentage: params.loan_valuation_percentage
          ? params.loan_valuation_percentage
          : "",

        property_address1: params.property_address1
          ? params.property_address1
          : "",
        property_address2: params.property_address2
          ? params.property_address2
          : "",
        property_address3: params.property_address3
          ? params.property_address3
          : "",
        property_town: params.property_town ? params.property_town : "",
        property_county: params.property_county ? params.property_county : "",
        property_country: params.property_country
          ? params.property_country
          : "",
        property_postcode: params.property_postcode
          ? params.property_postcode
          : "",
        note_text: params.note_text ? params.note_text : "",
        report_note: params.report_note ? params.report_note : "",
        provider_contact_name: params.provider_contact_name
          ? params.provider_contact_name
          : "",
        provider_phone: params.provider_phone ? params.provider_phone : "",
        provider_address1: params.provider_address1
          ? params.provider_address1
          : "",
        provider_address2: params.provider_address2
          ? params.provider_address2
          : "",
        provider_address3: params.provider_address3
          ? params.provider_address3
          : "",
        provider_town: params.provider_town ? params.provider_town : "",
        provider_county: params.provider_county ? params.provider_county : "",
        provider_country: params.provider_country
          ? params.provider_country
          : "",
        provider_postcode: params.provider_postcode
          ? params.provider_postcode
          : "",
        provider_note: params.provider_note ? params.provider_note : "",
      };
    }
    return paramVal;
  };
  const setLiabilityReviewFieldValues = (params: any) => {
    let paramVal = {};
    paramVal = {
      ...paramVal,

      review_assigned: params.review_assigned ? params.review_assigned : "",
      review_completed: params.review_completed ? params.review_completed : "",
      review_date: params.review_date ? params.review_date : "",
      review_interval: params.review_interval ? params.review_interval : "",
      review_reason: params.review_reason ? params.review_reason : "",
    };

    return paramVal;
  };
  const setPaymentFieldValues = (params: any) => {
    let paymentParamVal = {};
    if (params?.payment !== undefined) {
      paymentParamVal = {
        ...paymentParamVal,
        start_date: params.payment.start_date ? params.payment.start_date : "",
        end_date: params.payment.end_date ? params.payment.end_date : "",
        amount: params.payment.amount ? params.payment.amount : "",
        frequency: params.payment.frequency ? params.payment.frequency : "",
        new_business_register: params.payment.new_business_register
          ? camalize(params.payment.new_business_register)
          : "",
        reinvestment: params.payment.reinvestment
          ? camalize(params.payment.reinvestment)
          : "",
        note_text: params.payment.note_text ? params.payment.note_text : "",
        payment_type: params.payment.payment_type
          ? params.payment.payment_type
          : "",
      };
    }
    return paymentParamVal;
  };
  const setPaymentComplainceFieldValues = (params: any) => {
    let paymentComplainceParamVal = {};
    if (params.payment_complience !== undefined) {
      paymentComplainceParamVal = {
        ...params.payment_complience,
        consultant: params.payment_complience.consultant
          ? params.payment_complience.consultant
          : "",
      };
    }
    return paymentComplainceParamVal;
  };
  const setcommissionFieldValues = (params: any) => {
    let ParamVal = {};
    if (params !== undefined) {
      ParamVal = {
        ...ParamVal,
        status: params.status ? params.status : "",
        commission_type: params.commission_type ? params.commission_type : "",
        payment_source: params.payment_source ? params.payment_source : "",
        frequency: params.frequency ? params.frequency : "",
        amount_to_company: params.amount_to_company
          ? params.amount_to_company
          : "",
        start_date: params.start_date ? params.start_date : "",
        end_date: params.end_date ? params.end_date : "",
        nb_register:
          mode == "view"
            ? getYes_NoConversion(params.nb_register)
            : params.nb_register,
        payment_count: params.payment_count ? params.payment_count : "",
        note_text: params.note_text ? params.note_text : "",
      };
    }
    return ParamVal;
  };

  const setActionsFieldValues = (params: any) => {
    let paymentParamVal = {};
    if (params !== undefined) {
      paymentParamVal = {
        ...paymentParamVal,
        business_date: params.business_date ? params.business_date : "",
        business_action: params.business_action ? params.business_action : "",
        business_assigned: params.business_assigned
          ? params.business_assigned
          : "",
        business_status: params.business_status ? params.business_status : "",
      };
    }

    return paymentParamVal;
  };
  const addLoadStandardActions = () => {
    if (selectedLoadStandardActions.length > 0) {
      let case_type_value = (activeItem as { [key: string]: any }).case_type;
      let contextVal = contextList[case_type_value]["actions"];
      if (formSettingsObj && formSettingsObj.data) {
        let configObj: any = checkIfKeyExist(
          formSettingsObj.data.formConfig,
          contextVal
        );

        if (
          configObj &&
          configObj.renderForm &&
          configObj.renderForm?.reference_id
        ) {
          let actReq: any = {};
          actReq[configObj.renderForm?.reference_id] = {
            customer_id: props.activeCustomerId,
            case_id: activeItem.liability_id,
            effective_date: formatedDate2(loadStandardActionDate),
            tracking_case_actions: [],
          };
          let selAct: any = [];
          selectedLoadStandardActions.map((v: any) => {
            selAct.push({
              tracking_id: v,
            });
          });
          actReq[configObj.renderForm?.reference_id]["tracking_case_actions"] =
            selAct;
          setLoading(true);
          apiServices.tackingActions
            .addStandardActions(actReq)
            .then((response) => {
              setLoading(false);
              if (response.data && response.data.data) {
                setSelectedLoadStandardActions([]);
                getLiabilitySubItems();
                setLoadStandardActionPopup(false);
                setLoadStandardActionDate(new Date());
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      }
    }
  };
  const loadActionSelected = (action: any) => {
    let act: any = [...selectedLoadStandardActions];

    const index = act.indexOf(action);
    if (index !== -1) {
      act.splice(index, 1);
    } else {
      act.push(action);
    }
    // setSelectedActions(act);

    setSelectedLoadStandardActions(act);
  };
  return (
    <div className="form-container">
      <OverlayLoader isLoading={loading} />
      {liabilityList.length === 0 && (formContext === "" || mode === "") && (
        <>
          <div
            style={{
              display: "flex",
              marginTop: "10px",
              marginBottom: "10px",
              justifyContent: "flex-end",
              marginRight: "10px",
            }}
          >
            {checkUserPermission(
              loggedUser,
              UserPermissionsList.ALLOW_UPDATE_CLIENT
            ) && (
                <Button
                  appearance="primary"
                  className="asc-button-primary"
                  icon={<Add16Filled />}
                  size="small"
                  onClick={() => {
                    setLiabilityCatPopup(true);
                  }}
                >
                  Add Liability
                </Button>
              )}
          </div>
        </>
      )}
      {formContext !== "" && mode !== "" && (
        <>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className={styles.btnWrapper}>
                {activeItem &&
                  Object.keys(activeItem).length !== 0 &&
                  currentPage !== "listing" && (
                    <>
                      <Button
                        shape="square"
                        size="small"
                        icon={<ChevronLeft20Regular />}
                        onClick={() => {
                          setLiabilityActiveTabLevel2("");
                          setDetailFormContext("");
                          setLiabilitySubitemsList([]);
                          setCurrentPage("listing");
                          setDetailPageSelectedRowIndex(0);
                        }}
                      >
                        <span style={{ color: "red" }}>Back to Liability</span>
                      </Button>
                    </>
                  )}
                {["Mortgages", "Loans/Lease/HP", "Credit Cards"].includes(
                  liabilityType
                ) &&
                  !convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("liability_payment_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        liabilityActiveTabLevel2 === "payment"
                          ? "black"
                          : undefined,
                      color:
                        liabilityActiveTabLevel2 === "payment"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActiveLiabilityItem("payment");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("liability_payment_disable_button") &&
                        activeItem &&
                        Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Payments
                  </Button>
                ) : (
                  ""
                )}
                {["Mortgages", "Loans/Lease/HP"].includes(liabilityType) &&
                  !convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("liability_action_hide_button") ? (
                  <Button
                    shape="square"
                    size="small"
                    style={{
                      background:
                        liabilityActiveTabLevel2 === "actions"
                          ? "black"
                          : undefined,
                      color:
                        liabilityActiveTabLevel2 === "actions"
                          ? "white"
                          : undefined,
                    }}
                    onClick={() => {
                      selectActiveLiabilityItem("actions");
                    }}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("liability_action_disable_button") &&
                        activeItem &&
                        Object.keys(activeItem).length !== 0
                        ? false
                        : true
                    }
                  >
                    Actions
                  </Button>
                ) : (
                  ""
                )}
              </div>
              {currentPage && currentPage === "listing" && mode === "view" && (
                <div className={styles.btnWrapper}>
                  {checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  ) && (
                      <Button
                        className="asc-button-primary"
                        appearance="primary"
                        icon={<Add16Filled />}
                        size="small"
                        onClick={() => {
                          setLiabilityCatPopup(true);
                        }}
                        disabled={
                          activeLiabilityTab === "review"
                            ? true
                              ? liabilityType !== "" &&
                                !["edit", "view"].includes(mode)
                                ? false
                                : true
                              : false
                            : false
                        }
                      >
                        Add Liability
                      </Button>
                    )}
                </div>
              )}
              {currentPage && currentPage === "detail" && (
                <div className={styles.btnWrapper}>
                  {
                    //   liabilityActiveTabLevel2 === "fund" && (
                    //   <Button
                    //     size="small"
                    //     appearance="outline"
                    //     icon={<DocumentFolder20Regular />}
                    //   >
                    //     Locate Fund
                    //   </Button>
                    // )
                  }
                  {liabilityActiveTabLevel2 === "actions" && (
                    <>
                      {checkUserPermission(
                        loggedUser,
                        UserPermissionsList.ALLOW_UPDATE_CLIENT
                      ) &&
                        !convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes("liability_load_standard_action_hide") && (
                          <Button
                            size="small"
                            appearance="outline"
                            disabled={convertStringToArray(
                              process.env.REACT_APP_DISABLE_CTA_BUTTON
                            ).includes(
                              "liability_load_standard_action_disable"
                            )}
                            onClick={() => {
                              setLoadStandardActionPopup(true);
                              // dispatch(
                              //   setDialogModalOptions({
                              //     open: true,
                              //     load_action: true,
                              //     updateSelectedActions: updateSelectedActions,
                              //     customerId: props.activeCustomerId,
                              //     reload: () => {
                              //       setReloadRequired(reloadRequired + 1);
                              //     },
                              //     cancel: {
                              //       onclick: () => {
                              //         setSelectedActions([]);
                              //         dispatch(
                              //           setDialogModalOptions({ open: false })
                              //         );
                              //       },
                              //       label: "Close",
                              //     },
                              //     apply: {
                              //       onclick: () => {
                              //         addLoadStandardActions();
                              //         // dispatch(
                              //         //   setDialogModalOptions({ open: false })
                              //         // );
                              //       },
                              //       label: "Save",
                              //       type: "primary",
                              //     },
                              //   })
                              // );
                            }}
                          >
                            Load Standard Actions
                          </Button>
                        )}
                    </>
                  )}

                  {liabilityActiveTabLevel2 === "valuations" &&
                    !convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("liability_use_case_fund_value") && (
                      <Button
                        size="small"
                        appearance="outline"
                        disabled={convertStringToArray(
                          process.env.REACT_APP_DISABLE_CTA_BUTTON
                        ).includes("liability_use_case_fund_value")}
                      >
                        Use Case Fund Value
                      </Button>
                    )}
                  {!convertStringToArray(
                    process.env.REACT_APP_HIDE_CTA_BUTTON
                  ).includes("liability_case_details_hide") ? (
                    <Button
                      size="small"
                      appearance="outline"
                      disabled={convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("liability_case_details_disable")}
                      icon={<DocumentBulletList20Regular />}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Case Details
                    </Button>
                  ) : (
                    ""
                  )}
                  {/* <Button
                    size="small"
                    appearance="primary"
                    className="asc-button-primary"
                    icon={<Add20Regular />}
                    disabled={
                      liabilityActiveTabLevel2Subtab === "paymentcomplaince"
                        ? true
                        : false
                    }
                    // disabled={
                    //   ["add", "edit"].includes(detailPageMode) ? true : false
                    // }
                    onClick={() => {
                      handleDetailFormContext(liabilityActiveTabLevel2);
                      setDetailPageMode("add");
                    }}
                  >
                    {camalize(`add ${liabilityActiveTabLevel2}`)}
                  </Button> */}
                  {checkUserPermission(
                    loggedUser,
                    UserPermissionsList.ALLOW_UPDATE_CLIENT
                  ) && (
                      <Button
                        size="small"
                        appearance="primary"
                        className="asc-button-primary"
                        icon={<Add20Regular />}
                        disabled={
                          liabilityActiveTabLevel2Subtab === "paymentcomplaince"
                            ? true
                            : false
                        }
                        onClick={() => {
                          liabilityActiveTabLevel2Subtab === "paymentCommission"
                            ? handleDetailFormContext(
                              liabilityActiveTabLevel2Subtab
                            )
                            : handleDetailFormContext(liabilityActiveTabLevel2);
                          setDetailPageMode("add");
                        }}
                      >
                        {liabilityActiveTabLevel2Subtab === "paymentCommission"
                          ? "Add Commission"
                          : camalize(`Add ${liabilityActiveTabLevel2}`)}
                      </Button>
                    )}
                </div>
              )}
            </div>
          </div>
          {!subItemLoading && (
            <>
              <div className={styles.tabList}>
                {currentPage === "listing" && (
                  <TabList selectedValue={activeLiabilityTab}>
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes("liability_hide_button") ? (
                      <Tab
                        value="liability"
                        onClick={() => {
                          setActiveLiabilityTab("liability");
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes("liability_disable_button")
                            ? false
                            : true
                        }
                      >
                        Liability
                      </Tab>
                    ) : (
                      ""
                    )}
                    {["Mortgages", "Loans/Lease/HP", "Credit Cards"].includes(
                      liabilityType
                    ) &&
                      !convertStringToArray(
                        process.env.REACT_APP_HIDE_CTA_BUTTON
                      ).includes("liability_review_hide_button") ? (
                      <Tab
                        value="review"
                        onClick={() => {
                          setActiveLiabilityTab("review");
                          // showReviewForm();
                        }}
                        disabled={
                          !convertStringToArray(
                            process.env.REACT_APP_DISABLE_CTA_BUTTON
                          ).includes("liability_review_disable_button") &&
                            activeItem &&
                            Object.keys(activeItem).length !== 0
                            ? false
                            : true
                        }
                      >
                        Review
                      </Tab>
                    ) : (
                      ""
                    )}
                  </TabList>
                )}
                {currentPage === "detail" && (
                  <>
                    {liabilityActiveTabLevel2 === "payment" && (
                      <TabList selectedValue={liabilityActiveTabLevel2Subtab}>
                        {!convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes("liability_payment_payment_hide_button") ? (
                          <Tab
                            value="payment"
                            onClick={() => {
                              setLiabilityActiveTabLevel2Subtab("payment");
                            }}
                            disabled={
                              !convertStringToArray(
                                process.env.REACT_APP_DISABLE_CTA_BUTTON
                              ).includes(
                                "liability_payment_payment_disable_button"
                              )
                                ? false
                                : true
                            }
                          >
                            Payment
                          </Tab>
                        ) : (
                          ""
                        )}
                        {!convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes(
                          "liability_payment_compliance_hide_button"
                        ) ? (
                          <Tab
                            value="paymentcomplaince"
                            onClick={() => {
                              setLiabilityActiveTabLevel2Subtab(
                                "paymentcomplaince"
                              );
                            }}
                            disabled={
                              !convertStringToArray(
                                process.env.REACT_APP_DISABLE_CTA_BUTTON
                              ).includes(
                                "liability_payment_compliance_disable_button"
                              ) &&
                                liabilitySubActiveItem &&
                                Object.keys(liabilitySubActiveItem).length !== 0
                                ? false
                                : true
                            }
                          >
                            Compliance
                          </Tab>
                        ) : (
                          ""
                        )}
                        {!convertStringToArray(
                          process.env.REACT_APP_HIDE_CTA_BUTTON
                        ).includes(
                          "liability_payment_commission_hide_button"
                        ) ? (
                          <Tab
                            value="paymentCommission"
                            onClick={() => {
                              setLiabilityActiveTabLevel2Subtab(
                                "paymentCommission"
                              );
                            }}
                            disabled={
                              !convertStringToArray(
                                process.env.REACT_APP_DISABLE_CTA_BUTTON
                              ).includes(
                                "liability_payment_commission_disable_button"
                              ) &&
                                liabilitySubActiveItem &&
                                Object.keys(liabilitySubActiveItem).length !== 0
                                ? false
                                : true
                            }
                          >
                            Commission
                          </Tab>
                        ) : (
                          ""
                        )}
                      </TabList>
                    )}
                    {liabilityActiveTabLevel2 === "withdrawals" && (
                      <TabList defaultSelectedValue="tab1">
                        <Tab value="tab1">Withdrawals</Tab>
                      </TabList>
                    )}
                  </>
                )}
              </div>

              <div className="form-container">
                {currentPage === "listing" ? (
                  <FormContainer
                    {...{
                      context: formContext,
                      defaultAction,
                      setFormContext,
                      setLiabilityType,
                      currentPage,
                      setLiabilityList,
                      mode,
                      setMode,
                      initialFormData,
                      handleReloadList,
                      activeItem,
                      activeCustomerId: props.activeCustomerId,
                      liabilityType,
                      notify: props.notify,
                      handleRowSelection,
                      liabilityList,
                      currentRecord,
                      caseDetails: activeLiabilityDetail,
                      activePartnerId: props.activePartnerId,
                      setActivePartnerId: props.setActivePartnerId
                    }}
                  />
                ) : (
                  <>
                    {liabilityActiveTabLevel2 === "paymentCommission" ? (
                      <>
                        {liabilitySubitemsList.length === 0 &&
                          detailFormContext === "" &&
                          detailPageMode === "" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "200px",
                              }}
                            >
                              <Label size="large" style={{ color: "red" }}>
                                There are no active records - click{" "}
                                {camalize(`add commission`)} to create a new
                                record
                              </Label>
                            </div>
                          )}

                        <FormContainerDetailPage
                          {...{
                            context: detailFormContext,
                            setDetailFormContext,
                            activeItem,
                            setMode: setDetailPageMode,
                            mode: detailPageMode,
                            initialFormData: initialDetailPageFormData,
                            activeCustomerId: props.activeCustomerId,
                            liabilityType,
                            detailPageMode,
                            setDetailPageMode,
                            liabilityActiveTabLevel2,
                            liabilityActiveTabLevel2Subtab,
                            handleReloadSublist,
                            notify: props.notify,
                            detailPageSelectedRowIndex,
                            liabilitySubitemsList,
                            liabilitySubActiveItem,
                            currentSubRecord,
                            activePaymentItem,
                            paymentValue,
                            activePartnerId: props.activePartnerId,
                            setActivePartnerId: props.setActivePartnerId
                          }}
                        />
                      </>
                    ) : (
                      currentPage === "detail" &&
                      detailPageMode !== "" && (
                        <FormContainerDetailPage
                          {...{
                            context: detailFormContext,
                            setDetailFormContext,
                            activeItem,
                            mode: detailPageMode,
                            setMode: setDetailPageMode,
                            initialFormData: initialDetailPageFormData,
                            activeCustomerId: props.activeCustomerId,
                            liabilityType,
                            detailPageMode,
                            setDetailPageMode,
                            liabilityActiveTabLevel2,
                            liabilityActiveTabLevel2Subtab,
                            handleReloadSublist,
                            notify: props.notify,
                            detailPageSelectedRowIndex,
                            liabilitySubitemsList,
                            liabilitySubActiveItem,
                            currentSubRecord,
                            activePaymentItem,
                            paymentValue,
                          }}
                        />
                      )
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
      {!subItemLoading &&
        currentPage === "listing" &&
        liabilityList.length > 0 ? (
        <>
          <div style={{ margin: 6 }}>
            <LiabilityListItems
              {...{
                context: formContext,
                data: liabilityList,
                activeItem: activeItem,
                handleRowAction,
                mode,
                liabilityRowSelection,
                handleRowSelection,
                setCurrentRecord,
              }}
            />
          </div>
        </>
      ) : (
        mode === "" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 200,
            }}
          >
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click Add Liability to create a new
              record
            </Label>
          </div>
        )
      )}
      {currentPage === "detail" && liabilitySubitemsList.length > 0 ? (
        <LiabilityDetails
          {...{
            context: detailFormContext,
            data: liabilitySubitemsList,
            liabilityActiveTabLevel2,
            handleDetailFormContext,
            detailPageMode,
            setDetailPageMode,
            handleRowAction,
            liabilitySubActiveItem,
            handleDetailPageRowSelection,
            detailPageSelectedRowIndex,
            setCurrentSubRecord,
            liabilityActiveTabLevel2Subtab,
            activePaymentItem,
          }}
        />
      ) : (
        currentPage === "detail" &&
        detailPageMode === "" &&
        liabilityActiveTabLevel2Subtab !== "paymentCommission" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Label size="large" style={{ color: "red" }}>
              There are no active records - click{" "}
              {camalize(`add ${liabilityActiveTabLevel2}`)} to create a new
              record
            </Label>
          </div>
        )
      )}

      {/* {!["view", "edit"].includes(mode) && (
        <div className={styles.wrapper}>
          <div>
            <Select
              id={selectId}
              onChange={(e) => {
                setLiabilityType(e.target.value);
                setFormContext("");
                setDetailFormContext("");
                setCurrentPage("");
                setActiveLiability("");
                setViewLiability(false);
                setCurrentPage("listing");
                setLiabilityActiveTabLevel2("");
                setDetailFormContext("");
                setActiveItem({});
                setMode("");
              }}
             
            >
            
              {liabilityTypes &&
                liabilityTypes.length > 0 &&
                liabilityTypes.map((item) => (
                  <option
                    value={item.value}
                    selected={
                      liabilityType === item.value && mode === "add"
                        ? true
                        : false
                    }
                  >
                    {item.label}
                  </option>
                ))}
            
            </Select>
          </div>
         
          <div>
            <Button
              className="asc-button-primary"
              appearance="primary"
              icon={<Add20Filled />}
              onClick={() => {
                setDefaultAction(false);
                setMode("add");
                getContextDetails(liabilityType, liabilityActiveTabLevel1);
              }}
              disabled={
                liabilityType !== "" && !["edit", "view"].includes(mode)
                  ? false
                  : true
              }
            >
              Add Liability
            </Button>
          </div>
          
        </div>
      )}

      <Divider style={{ marginTop: "6px" }} /> */}

      <OverlayDrawer
        position={"end"}
        open={isOpen}
        onOpenChange={(_, { open }) => setIsOpen(open)}
        className={"common-drawer"}
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => setIsOpen(false)}
              />
            }
          >
            Case Details
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody>{CaseDetails(activeItem)}</DrawerBody>
      </OverlayDrawer>
      <Dialog open={liabilityCatPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <div className={styles.field}>
                <Label id={labelId} weight="semibold">
                  Select Liability Category
                </Label>
                <RadioGroup
                  // role="radiogroup"
                  value={selectedLiabilityCatPopup}
                  aria-labelledby={labelId}
                  className="radioItems"
                  onChange={(_, data) => {
                    setSelectedLiabilityCatPopup(data.value);
                  }}
                >
                  <Radio name={radioName} value="Mortgages" label="Mortgages" />
                  <Radio
                    name={radioName}
                    value="Loans/Lease/HP"
                    label="Loan and Hire Purchase"
                  />
                  <Radio
                    name={radioName}
                    value="Credit Cards"
                    label="Credit Cards"
                  />
                </RadioGroup>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => {
                    setSelectedLiabilityCatPopup("");
                    setLiabilityCatPopup(false);
                  }}
                  size="small"
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={() => {
                  if (selectedLiabilityCatPopup !== "") {
                    setMode("add");
                    setLiabilityType(selectedLiabilityCatPopup);
                    setLiabilityCatPopup(false);

                    getContextDetails(
                      selectedLiabilityCatPopup,
                      liabilityActiveTabLevel1
                    );
                    setSelectedLiabilityCatPopup("");
                  }
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <Dialog open={loadStandardActionPopup} modalType="modal">
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <LoadStandardAction
                loadStandardActionDate={loadStandardActionDate}
                setLoadStandardActionDate={setLoadStandardActionDate}
                selectedLoadStandardActions={selectedLoadStandardActions}
                loadActionSelected={loadActionSelected}
                type="case"
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  size="small"
                  onClick={() => {
                    setSelectedLoadStandardActions([]);
                    setLoadStandardActionPopup(false);
                  }}
                >
                  Close
                </Button>
              </DialogTrigger>
              <Button
                appearance="primary"
                className="asc-button-primary"
                size="small"
                onClick={addLoadStandardActions}
              >
                Submit
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      
    </div>
  );
};

export default LiabilityForm;
