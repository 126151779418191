import React from "react";
import { useParams } from "react-router-dom";
import Account from "./account";
import CommunicationDetail from "./communicationDetail";

function ProfileRouting(props: any) {
  const { section } = useParams();

  return (
    <div>
      {section === "account" && (
        <Account
          mode={props.mode}
          setMode={props.setMode}
          notify={props.notify}
        />
      )}
      {section === "communicationDetail" && <CommunicationDetail mode={props.mode}
          setMode={props.setMode}
          notify={props.notify} />}
    </div>
  );
}

export default ProfileRouting;
