import * as yup from "yup";
export function createYupSchema(formFields: any) {
  const yupSchema: { [key: string]: any } = {};
  let fieldSchema: any = null;

  formFields.forEach((items: any) => {
    const { field, validationType, validations, required } = items;

    if (
      ["string", "number", "date", "max", "phoneNumber"].includes(
        validationType
      )
    ) {
      switch (validationType) {
        case "string":
          fieldSchema = (yup as any)[validationType]();
          break;
        case "max":
          let validationType2 = "string";
          fieldSchema = (yup as any)[validationType2]();
          break;
        case "number":
          fieldSchema = yup
            .number()
            .typeError("Field must be a number")
            .min(0, "Field must be a positive number");
          break;

        case "date":
          fieldSchema = yup.date().nullable();
          break;
        default:
          fieldSchema = (yup as any).string();
          break;
      }
    }
    if (validations && validations.length > 0) {
      validations.forEach((validation: any) => {
        switch (validation.type) {
          case "required":
            fieldSchema = fieldSchema.required(validation.message);
            break;
          case "min":
            fieldSchema = fieldSchema.min(validation.value, validation.message);
            break;
          case "max":
            fieldSchema = fieldSchema.max(validation.value, validation.message);
            break;
          case "email":
            fieldSchema = yup
              .string()
              .matches(
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                validation.message
              );
            break;
          case "pattern":
            fieldSchema = fieldSchema.matches(
              new RegExp(validation.value),
              validation.message
            );
            break;
          case "maxLength":
            fieldSchema = fieldSchema.test(
              "maxLength",
              validation.message,
              (number: number) => String(number).length <= validation.value
            );
            break;
          case "minLength":
            fieldSchema = fieldSchema.test(
              "minLength",
              validation.message,
              (number: number) => String(number).length >= validation.value
            );
            break;
          case "phoneNumber":
            fieldSchema = yup
              .string()
              .matches(/^(\+)?(?:[0-9] ?[\(\)-]?){7,20}[0-9]$/, {
                message: "Number should have atleast 8 digit",
                excludeEmptyString: true,
              });
            break;
          case "decimal":
            fieldSchema = yup
              .number()
              .nullable() // Allow empty values
              .typeError("Field must be a number")
              .test("decimal", validation.message, (value) => {
                if (value === null || value === undefined) {
                  return true; // Allow empty values
                }
                if (typeof value === "number") {
                  return !!(value + "").match(/^\d{1,14}(\.\d{1,4})?$/);
                }
                return false;
              });

            const isRequired = validations.some(
              (item: any) => item.type === "required"
            );

            if (isRequired) {
              fieldSchema = fieldSchema.min(
                0.0001,
                "Value must be greater than 0"
              );
            }
            break;
          case "maxDate":
            if (validation.value !== undefined && validation.value !== "") {
              if (validation.value === "today") {
                fieldSchema = fieldSchema.max(new Date(), validation.message);
              } else {
                fieldSchema = fieldSchema.max(
                  new Date(validation.value),
                  validation.message
                );
              }
            } else {
            }

            break;
          case "minDate":
            fieldSchema = fieldSchema.min(
              new Date(validation.value),
              validation.message
            );
            break;
          default:
            break;
        }
        yupSchema[field] = fieldSchema;
      });
    }
  });
  return yupSchema;
}
