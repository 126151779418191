import { useState, useEffect } from "react";
import {
  TabList,
  Tab,
  useToastController,
  useId,
  Subtitle2,
  Caption1,
  Caption1Strong,
} from "@fluentui/react-components";
import PartnerFormContainer from "./PartnerFormContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { formBuilder } from "../../../utils/formGenerator";
import apiServices from "../../../service";
import {
  camalize,
  convertStringToArray,
  removeEmptyValues,
} from "../../../utils";
import PartnerFormContainerWithList from "./PartnerFormContainerWithList";
import { addBreadcrumbs } from "../../../redux/appSettingsSlice";
import VulnerabilityForm from "../Vulnerability/VulnerabilityForm";
import OverlayLoader from "../../../components/OverlayLoader";

type tabItems = {
  label: string;
  value: string;
}[];

const tabListData: tabItems = [
  {
    label: "Name & Address",
    value: "PARTNER_NAME_ADDRESS",
  },
  {
    label: "Contact",
    value: "PARTNER_CONTACT",
  },
  {
    label: "Nationality",
    value: "PARTNER_NATIONALITY",
  },
  {
    label: "Personal",
    value: "PARTNER_PERSONAL",
  },
  {
    label: "Vulnerability",
    value: "PARTNER_VULNERABILITY",
  },
  {
    label: "Health Note",
    value: "PARTNER_HEALTH_NOTE",
  },
  {
    label: "Employment",
    value: "PARTNER_EMPLOYMENT",
  },
  {
    label: "Attitude To Risk",
    value: "PARTNER_ATTITUDE_TO_RISK",
  },
  {
    label: "Identity",
    value: "PARTNER_IDENTITY",
  },
  {
    label: " Objectives",
    value: "PARTNER_OBJECTIVES",
  },
  {
    label: " Service Type",
    value: "PARTNER_SERVICE_TYPE",
  },
];

const commissionClientTablistData: tabItems = [
  {
    label: "Name & Address",
    value: "COMMISSION_PARTNER",
  },

  {
    label: "Service Type",
    value: "COMMISSION_PARTNER_SERVICE_TYPE",
  },
];

type FormContainerProps = {
  style?: React.CSSProperties;
  title?: string;
  mode?: string;
  [x: string]: any;
  changeMode?: (mode: string) => void;
  changeTab?: (index: number) => void;
};

type configFm = {
  context: string;
  actionUrl: {
    create: string;
    edit: string;
    redirect: string;
  };
  renderForm: {
    reference_id: string;
    general_fields: string[];
    custom_fields: string[];
  };
};

const PartnerForm = (props: FormContainerProps) => {
  const [tabListItems, setTabListItems] = useState<tabItems>(tabListData);
  const [selectedValue, setSelectedValue] = useState("PARTNER_NAME_ADDRESS");
  const [partnerFormMode, setPartnerFormMode] = useState("");
  const [activePartnerId, setActivePartnerId] = useState("");
  const [tabMenuIndex, setTabMenuIndex] = useState({
    previous: -1,
    current: 0,
    next: -1,
  });
  const [configForm, setConfigForm] = useState<configFm>();
  const [formInitialValue, setFormInitialValue] = useState({});
  const [partnerInitialData, setPartnerInitialData] = useState({});
  const [commissionData, setCommissionData] = useState([]);
  const isFormDataUpdated = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const [attitudeToRiskData, setAttitudeToRiskData] = useState([]);
  const dispatch: AppDispatch = useDispatch();
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const getTablistLabelByValue = (value: string) => {
    const item = tabListData.find((tabItem) => tabItem.value === value);
    return item ? item.label : null;
  };
  useEffect(() => {
    if (selectedValue != "") {
      setFormContextConfig(selectedValue);
      updateTabMenuIndex(selectedValue);
      handleBreadcrumbs();
    }
  }, [selectedValue, formSettingsObj]);

  useEffect(() => {
    if (
      Object.keys(props.initialData).length !== 0 &&
      props.initialData.associated_type === 0 &&
      props.initialData.associated_with !== "" &&
      props.initialData.associated_with !== null
    ) {
      getPartnerDetail(props.initialData.associated_with);
      setActivePartnerId(props.initialData.associated_with);
      if (props.mode !== undefined) {
        setPartnerFormMode(props?.mode);
      }
    } else {
      setPartnerFormMode("add");
    }
  }, [props.initialData]);

  useEffect(() => {
    if (["view", "edit"].includes(partnerFormMode) && activePartnerId !== "") {
      //   call get api
      if (selectedValue === "PARTNER_ATTITUDE_TO_RISK") {
        getAttitudeToRisk(activePartnerId);
      } else if (selectedValue === "COMMISSION_PARTNER") {
        getCommissionPartner(activePartnerId);
      } else getPartnerDetail(activePartnerId);
    }
  }, [partnerFormMode, selectedValue]);

  useEffect(() => {
    if (props.module != undefined && props.module == "commission") {
      setTabListItems(commissionClientTablistData);
      setSelectedValue("COMMISSION_PARTNER");
    }
  }, [props.module]);

  const getAttitudeToRisk = (id: string) => {
    apiServices.attitudeToRisk
      .getList(id)
      .then((response: any) => {
        setAttitudeToRiskData(response.data.data);
      })
      .catch((e: Error) => {
        console.log("error", e);
      });
  };

  useEffect(() => {
    if (activePartnerId !== "") {
      //   call get api
      getPartnerDetail(activePartnerId);
    }
  }, [activePartnerId]);

  const getCommissionPartner = (id: string) => {
    apiServices.commissionModule.commissionClient
      .getList(id)
      .then((response: any) => {
        setCommissionData(response.data.data);
      })
      .catch((e: Error) => {
        console.log("error", e);
      });
  };

  const reloadDetail = () => {
    if (selectedValue === "PARTNER_ATTITUDE_TO_RISK") {
      getAttitudeToRisk(props.initialData.associated_with);
    } else if (selectedValue === "COMMISSION_PARTNER") {
      getCommissionPartner(props.initialData.associated_with);
    } else {
      getPartnerDetail(props.initialData.associated_with);
    }
  };

  const handleBreadcrumbs = () => {
    if (props.menu !== undefined && props.menu === "partner") {
      let currentTabLabel = tabListData.find(
        (item: any) => item.value === selectedValue
      );
      dispatch(
        addBreadcrumbs([
          {
            key: 0,
            value: "Home",
            link: "/",
          },
          {
            key: 1,
            value: "Clients",
            link: "/clients",
          },
          {
            key: 2,
            value: "Client",
            link: "/client",
          },
          {
            key: 3,
            value: camalize(props.menu),
          },
          {
            key: 4,
            value: currentTabLabel?.label,
          },
          // {
          //   key: 2,
          //   value: currentTabLabel?.label,
          // }
        ])
      );
    }
    // alert("hasndle breadcrumbs");
    // if (verticalTab === "Client") {
    //   let currentTabLabel = tabListData.find(
    //     (item: any) => item.value === selectedValue
    //   );
    //   dispatch(
    //     addBreadcrumbs([
    //       {
    //         key: 0,
    //         value: "Home",
    //         link: "/",
    //       },
    //       {
    //         key: 1,
    //         value: "Clients",
    //         link: "/clients",
    //       },
    //       {
    //         key: 2,
    //         value: verticalTab,
    //       },
    //       {
    //         key: 3,
    //         value: currentTabLabel?.label,
    //       },
    //       // {
    //       //   key: 2,
    //       //   value: currentTabLabel?.label,
    //       // }
    //     ])
    //   );
    // } else {
    //   dispatch(
    //     addBreadcrumbs([
    //       {
    //         key: 0,
    //         value: "Home",
    //         link: "/",
    //       },
    //       {
    //         key: 1,
    //         value: "Clients",
    //         link: "/clients",
    //       },
    //       {
    //         key: 2,
    //         value: "Client",
    //       },
    //       {
    //         key: 3,

    //         value: mapVerticalTabs(verticalTab),
    //       },
    //     ])
    //   );
    // }
  };
  const [loading, setLoading] = useState(false);

  const getPartnerDetail = (id: any) => {
    setLoading(true);
    apiServices.client
      .getClientDetail(id)
      .then((response: any) => {
        if (!response.data.isError) {
          let singleData = {};
          if (
            response.data.data &&
            Object.keys(response.data.data).length !== 0
          ) {
            if (response.data.data?.customer_id) {
              singleData = {
                ...singleData,
                customer_id: response.data.data.customer_id,
              };
            }
            if (response.data.data.NameAndAddress) {
              singleData = {
                ...singleData,
                ...response.data.data.NameAndAddress,
              };
            }
            if (response.data.data.Contact) {
              singleData = {
                ...singleData,
                ...response.data.data.Contact,
              };
            }
            if (response.data.data.Nationality) {
              singleData = {
                ...singleData,
                ...response.data.data.Nationality,
              };
            }
            if (response.data.data.Personal) {
              singleData = {
                ...singleData,
                ...response.data.data.Personal,
              };
            }
            if (response.data.data.HealthNotes) {
              singleData = {
                ...singleData,
                ...response.data.data.HealthNotes,
              };
            }
            if (response.data.data.identity) {
              singleData = {
                ...singleData,
                identity: response.data.data.identity,
              };
            }
            if (response.data.data.objectives) {
              singleData = {
                ...singleData,
                objectives: response.data.data.objectives,
              };
            }
            if (response.data.data.employment) {
              singleData = {
                ...singleData,
                employment: response.data.data.employment,
              };
            }
            if (response.data.data.servicetype) {
              singleData = {
                ...singleData,
                servicetype: response.data.data.servicetype,
              };
            }
            if (response.data.data.attitudetorisk) {
              singleData = {
                ...singleData,
                attitudetorisk: response.data.data.attitudetorisk,
              };
            }
          }
          // setInitialData(singleData);
          setPartnerInitialData(removeEmptyValues(singleData));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error in partner", err);
      });
  };

  const onTabSelect = (event: any, data: any) => {
    setSelectedValue(data.value);
  };

  const changeTab = (index: number) => {
    if (index !== -1) setSelectedValue(tabListItems[index]["value"]);
  };

  const updateTabMenuIndex = (context: string) => {
    if (tabListItems.length > 0) {
      let currentVal = tabListItems.findIndex(
        (obj) => obj["value"] === context
      );
      let prevVal = currentVal === 0 ? -1 : currentVal - 1;
      let nextVal =
        tabListItems.length - 1 !== currentVal ? currentVal + 1 : -1;

      setTabMenuIndex((prevState) => ({
        ...prevState,
        previous: prevVal,
        current: currentVal,
        next: nextVal,
      }));
    }
  };

  const setFormContextConfig = (context: string) => {
    if (formSettingsObj.data && formSettingsObj.data.formConfig !== undefined) {
      let configObj: any = {};
      if (
        props.module != undefined &&
        props.module == "commission" &&
        formSettingsObj.data.commission !== undefined
      ) {
        configObj = formSettingsObj.data.commission.formConfig.find(
          (item: any) => item.context === context
        );
      } else {
        configObj = formSettingsObj.data.formConfig.find(
          (item: any) => item.context === context
        );
      }
      if (configObj) {
        setConfigForm(configObj);

        let { initialValues, inputs, validationSchema } = formBuilder({
          context: context,
          formSettings:
            props.module != undefined && props.module == "commission"
              ? formSettingsObj.data.commission
              : formSettingsObj.data,
        });
        setFormInitialValue({ initialValues, inputs, validationSchema });
      }
    }
  };

  return (
    <>
      <OverlayLoader isLoading={loading} />
      <div className="form-container">
        <header className="cl-page-header">
          <Subtitle2>Partner Details</Subtitle2>

          <nav className="cl-breadcrumbs">
            <ul>
              <li
                onClick={() => {
                  props.setVerticalTab("Client");
                }}
              >
                <a>
                  {" "}
                  <Caption1>Client Detail</Caption1>
                </a>

                <span className="cl-separator"> / </span>
              </li>
              <li>
                <Caption1>Partner Details</Caption1>

                <span className="cl-separator"> / </span>
              </li>
              <li>
                <Caption1Strong>
                  {getTablistLabelByValue(selectedValue)}
                </Caption1Strong>
              </li>
            </ul>
          </nav>
        </header>
        <div>
          <div>
            <TabList selectedValue={selectedValue} onTabSelect={onTabSelect}>
              {tabListItems &&
                tabListItems.length > 0 &&
                tabListItems.map((item, i) => (
                  <Tab
                    key={i}
                    value={item.value}
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes(item.value)
                        ? partnerFormMode === "add"
                          ? tabMenuIndex.current >= i
                            ? false
                            : true
                          : undefined
                        : true
                    }
                  >
                    {!convertStringToArray(
                      process.env.REACT_APP_HIDE_CTA_BUTTON
                    ).includes(item.value) ? (
                      <>{item.label}</>
                    ) : (
                      ""
                    )}
                  </Tab>
                ))}
            </TabList>
          </div>
          {selectedValue !== "" && selectedValue === configForm?.context && (
            <>
              {[
                "PARTNER_EMPLOYMENT",
                "PARTNER_ATTITUDE_TO_RISK",
                "PARTNER_SERVICE_TYPE",
                "PARTNER_IDENTITY",
                "PARTNER_OBJECTIVES",
                "COMMISSION_PARTNER_SERVICE_TYPE",
              ].includes(selectedValue) ? (
                <PartnerFormContainerWithList
                  context={selectedValue}
                  initialData={partnerInitialData}
                  tabMenuIndex={tabMenuIndex}
                  attitudeToRiskData={attitudeToRiskData}
                  changeTab={changeTab}
                  closePage={props.closePage}
                  activeCustomerId={props.activeCustomerId}
                  setActiveItem={props.setActiveItem}
                  notify={props.notify}
                  getList={props.getList}
                  dialogModalOptions={props.dialogModalOptions}
                  setDialogModal={props.setDialogModal}
                  config={configForm}
                  reloadData={reloadDetail}
                  setPartnerFormMode={setPartnerFormMode}
                  getClientDetail={props.getClientDetail}
                  module={props.module}
                  commissionData={commissionData}
                />
              ) : (
                <PartnerFormContainer
                  context={selectedValue}
                  mode={partnerFormMode}
                  changeMode={props.changeMode}
                  initialData={partnerInitialData}
                  tabMenuIndex={tabMenuIndex}
                  changeTab={changeTab}
                  closePage={props.closePage}
                  activeCustomerId={props.activeCustomerId}
                  setActiveItem={props.setActiveItem}
                  notify={props.notify}
                  getList={props.getList}
                  dialogModalOptions={props.dialogModalOptions}
                  setDialogModal={props.setDialogModal}
                  clientFormSubmited={props.clientFormSubmited}
                  resetClientFormSubmited={props.resetClientFormSubmited}
                  config={configForm}
                  setActivePartnerId={setActivePartnerId}
                  setPartnerFormMode={setPartnerFormMode}
                  getClientDetail={props.getClientDetail}
                  module={props.module}
                  commissionData={commissionData}
                />
              )}
            </>
          )}
          {selectedValue === "PARTNER_VULNERABILITY" && (
            <VulnerabilityForm
              notify={props.notify}
              context={selectedValue}
              activeCustomerId={activePartnerId}
              tabMenuIndex={tabMenuIndex}
              changeTab={changeTab}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PartnerForm;
