import { Button, Caption1Strong, Input, Label, Toaster, useId } from '@fluentui/react-components';
import { useFormik } from 'formik';
import * as Yup from "yup";
import React, { useEffect, useState } from 'react'
import ASCButton from '../../components/Button';
import { Edit24Filled } from "@fluentui/react-icons";
import { useSelector } from 'react-redux';
import apiServices from '../../service';

function CommunicationDetail(props:any) {
  const toasterId = useId("toaster");
  const { userProfileInfo } = useSelector((state: any) => state.authUser);
  const [userInfo, setUserInfo] = useState<any>({})
  const [formField, setFormField] = useState({
    user_email: "",
    appPassword: ""
  });
  const formik = useFormik({
    initialValues: {
      user_email: formField?.user_email ? formField.user_email : "",
      appPassword: formField?.appPassword ? formField.appPassword : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // Handle form submission logic
      // console.log(values);
      // updateProfile(values);
    },
  });
  const getUserDetail = (userId: any) => {
    apiServices.systemManager.manageUsers
      .getDetail(userId)
      .then((response: any) => {
        setUserInfo(response.data.user.UserId)      
      })
      .catch((err: any) => console.log(err));
  };
  
  useEffect(() => {
    getUserDetail(userProfileInfo.user_id)
  }, [])
  console.log(userInfo)
  console.log(userProfileInfo.user_id, "userProfileInfo.UserId")

  return (
    <>
    <Toaster toasterId={toasterId} />
      <div className="profile_content">
        <div>
          <h2>Communication Detail</h2>
          <div className="profile_view">
            <Label>Email</Label>
            {props.mode === "view" && <Label>{userProfileInfo.user_email}</Label>}
            {props.mode === "edit" && (
              <>
                <Input
                  name={"user_email"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={userProfileInfo.user_email}
                />
                {formik.errors.user_email && (
                  <Caption1Strong className="form-field-error-msg">
                    {formik.errors.user_email}
                  </Caption1Strong>
                )}
              </>
            )}
            <Label>Password</Label>
            {props.mode === "view" && <Label>{"********"}</Label>}
            {props.mode === "edit" && (
              <>
                <Input
                  name={"appPassword"}
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formField.appPassword}
                />
              </>
            )}
          </div>
          {props.mode === "edit" && (
            <div className="action_buttons">
              <Button
                onClick={() => {
                  props.setMode("view");
                }}
              >
                Cancel
              </Button>
              <ASCButton
                shape={"rounded"}
                label={"Save"}
                className="asc-button-primary"
                appearance="primary"
                onItemClick={() => formik.handleSubmit()}
                size="medium"
              />
            </div>
          )}
        </div>
        <div>
          {props.mode === "view" && (
            <Button
              onClick={() => {
                props.setMode("edit");
              }}
              icon={<Edit24Filled />}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default CommunicationDetail
