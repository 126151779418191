import React, { useEffect, useState } from "react";
import { Dropdown, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import {
  Button,
  Label,
  Dialog,
  DialogSurface,
  DialogBody,
  DialogActions,
  DialogContent,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  DialogTitle,
  PresenceBadge,
  Badge,
  Spinner,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  AttachRegular,
  DismissRegular,
} from "@fluentui/react-icons";
import ClientSelection from "./ClientSelection";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.css";
import "../../client/style.css";
import Dropzone from "react-dropzone";
import apiServices from "../../../service";
import OverlayLoader from "../../../components/OverlayLoader";

interface Template {
  CommunicationTemplateId: string;
  Title: string;
  Body: string;
  Description: string;
}
type ReplacementMap = { [key: string]: string };

type selectedValue = {
  customer_id: string;
  first_names: string;
  last_name: string;
  email: string;
};
const modules = {
  toolbar: false,
};
const TemplateDropdown: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [clients, setClients] = useState<selectedValue[]>([]);
  const [messageBody, setMessageBody] = useState<string>("");
  const [showClientSelection, setShowClientSelection] = useState(false);
  const [selectedClient, setSelectedClient] = useState<selectedValue[]>([]);
  const [previewList, setPreviewList] = useState<any>([]);
  const [mailCount, setMailCount] = useState(0);
  const [totalMails, setTotalMails] = useState(0);
  const [sendingMail, setSendingMail] = useState<boolean>(false);
  const [mailStatus, setMailStatus] = useState([]);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState("");
  const { userProfileInfo } = useSelector((state: any) => state.authUser);
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File[] }>(
    {}
  );
  const [getPreview, setGetPreview] = useState<any>([]);
  const [template, setTemplate] = useState<Template[]>([]);
  const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
      width: "50%",
      borderRadius: "6px",
    },
    callout: {
      maxHeight: "200px",
    },
  };

  const handleDropdownChangetemplate = (
    event: React.FormEvent<HTMLDivElement>,
    item?: any
  ): void => {
    if (item) {
      console.log("selectedClient====", selectedClient);
      setSelectedTemplate(item.text);
      setSelectedTemplateId(item.key);
      // getMessageBody(item.key, selectedClient[0]["customer_id"]);
      // // getCreatePreview(selectedClient)
      generatePreviewList(item.key, selectedClient);
    }
  };

  const handleContinue = (): void => {
    setShowPopup(false);
    setShowConfirmation(true);
  };

  const handleConfirmation = (confirmed: boolean): void => {
    setShowConfirmation(false);
    if (confirmed) {
      resetPage();
    }
  };

  const generatePreviewList = (templateId: string, selClients: any) => {
    if (templateId !== undefined && selClients && selClients.length > 0) {
      selClients.map((client: any) => getMessageBody(templateId, client));
    }
  };

  const getTemplateList = () => {
    apiServices.notification
      .getList()
      .then((response: any) => {
        if (!response.data.isError) {
          if (response.data) {
            setTemplate(response.data);
          }
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getMessageBody = (templateId: string, customer: any) => {
    setLoading(true);
    apiServices.notification
      .createContent(templateId, customer.customer_id)
      .then((response: any) => {
        setLoading(false);
        if (response.data) {
          const { Body, Subject, Placeholders } = response.data;
          const messageBody = generateMessage(Body, Placeholders);
          const newPreviewItem = {
            body: messageBody,
            subject: Subject,
            placeholders: Placeholders,
            customer,
            edited: false,
          };
          setPreviewList((prevList: any) => [...prevList, newPreviewItem]);
        }
      })
      .catch((e: Error) => {
        setLoading(false);
        console.log(e);
      });
  };

  const generateMessage = (
    template: string,
    replacements: { [key: string]: string }
  ) => {
    let result = template;

    for (const [placeholder, value] of Object.entries(replacements)) {
      const regex = new RegExp(`{{${placeholder}}}`, "g");
      result = result.replace(regex, value);
    }
    return result;
  };

  useEffect(() => {
    getTemplateList();
  }, []);

  const handleClientSelection = (selectedIds: any) => {
    if (selectedIds && selectedIds.length > 0) {
      setSelectedClient(selectedIds);
      console.log(selectedIds, "-------selectedIds-------");
    }

    // const selectedClient = clients.find((client: any) =>
    //   selectedIds.includes(client?.customer_id)
    // );

    // if (selectedClient) {
    //   setSelectedOptions([selectedClient?.customer_id]);
    //   setSelectedClient([selectedClient]);
    // }
  };

  const removeFile = (clientId: string, index: number) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = { ...prevFiles };
      newFiles[clientId].splice(index, 1);
      return newFiles;
    });
  };

  const handleSendMail = () => {
    if (previewList.length > 0) {
      setShowConfirmation(false);
      setSendingMail(true);
  
      const editedItems = previewList.filter((item: any) => item.edited === true);
      const nonEditedItems = previewList.filter((item: any) => item.edited === false);
  
      console.log("editedItems:::", editedItems);
      console.log("nonEditedItems:::", nonEditedItems);
  
      const initialStatus = previewList.map((item: any) => ({
        customer: item.customer,
        status: "pending",
        item,
      }));
      setMailStatus(initialStatus);
  
      const updateStatus = (index: number, status: string) => {
        setMailStatus((prevList) => {
          const newList: any = [...prevList];
          newList[index] = { ...newList[index], status };
          return newList;
        });
      };
  
      const sendIndividualEmail = (item: any, index: number) => {
        const formData = new FormData();
        formData.append("customerId", item.customer.customer_id);
        formData.append("subject", selectedTemplate);
        formData.append("cc", userProfileInfo.user_email);
        formData.append("from", userProfileInfo.user_email);
        formData.append("body", item.body);
        const files = uploadedFiles[item.customer.customer_id] || [];
        files.forEach((file: File) => {
          formData.append("files", file);
        });
        return apiServices.notification
          .sendEmail(formData)
          .then((res) => {
            updateStatus(index, "completed");
            setMailCount((prevCount) => prevCount + 1);
          })
          .catch((err) => {
            console.log(err);
            updateStatus(index, "failed");
          });
      };

      // const sendBulkEmail = (items: any[]) => {
      //   previewList.forEach((item: any, index: number) => {
      //     const formData = new FormData();
      //     formData.append("customerId", item.customer.customer_id);
      //     formData.append("subject", selectedTemplate);
      //     formData.append("cc", userProfileInfo.user_email);
      //     formData.append("from", userProfileInfo.user_email);
      //     formData.append("body", item.body);
      //     const files = uploadedFiles[item.customer.customer_id] || [];
      //     files.forEach((file: File) => {
      //       formData.append("files", file);
      //     });
      //     apiServices.notification
      //       .sendEmail(formData)
      //       .then((res) => {
      //         setLoading(false);
      //         setMailCount((prevCount) => prevCount + 1);
      //         setMailStatus((prevStatus: any) =>
      //           prevStatus.map((status: any, i: number) =>
      //             i === index ? { ...status, status: "completed" } : status
      //           )
      //         );
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //         setLoading(false);
      //         setMailStatus((prevStatus: any) =>
      //           prevStatus.map((status: any, i: number) =>
      //             i === index ? { ...status, status: "failed" } : status
      //           )
      //         );
      //       });
      //   });
      // };
  
      const individualPromises = editedItems.map((item: any, index: number) =>
        sendIndividualEmail(item, index)
      );
  
      Promise.all(individualPromises)
        .then(() => {
          console.log("All individual emails sent");
          if (nonEditedItems.length > 0) {
            return Promise.all(nonEditedItems.map((item: any, index: number) =>
              sendIndividualEmail(item, index + editedItems.length)
            ));
          }
        })
        .then(() => {
          if (nonEditedItems.length > 0) {
            console.log("Bulk emails sent");
          }
        })
        .catch((error) => {
          console.error("Error sending emails:", error);
        });
  
      setTotalMails(previewList.length);
      setMailCount(0);
      setShowConfirmation(false);
      setSendingMail(true);
      setLoading(true);
    }
  };
  

  useEffect(() => {
    if (
      mailStatus.length > 0 &&
      mailStatus.every((item: any) => item.status === "completed")
    ) {
      const timer = setTimeout(() => {
        setSendingMail(false);
      }, 1000);
      resetPage();
      return () => clearTimeout(timer); 
    }
  }, [mailStatus]);

  const resetPage = () => {
    setSelectedTemplate("");
    setSelectedTemplateId("");
    setShowPopup(false);
    setShowConfirmation(false);
    setSelectedOptions([]);
    setSelectedClient([]);
    setMessageBody("");
    setPreviewList([]);
  };

  const MAX_DISPLAY_COUNT = 5;
  return (
    <div className="send_email">
      <OverlayLoader isLoading={loading} />
      <div className="send_mail_client">
        <Label className="label">Select Client(s)</Label>
        <Button
          onClick={() => setShowClientSelection(true)}
          className="client_button"
        >
          {selectedClient.length > 0 ? (
            <>
              {selectedClient
                .slice(0, MAX_DISPLAY_COUNT)
                .map((client, index) => (
                  <span key={index}>
                    {client.last_name}
                    {index < MAX_DISPLAY_COUNT - 1 &&
                      index < selectedClient.length - 1 && (
                        <>
                          {","}
                          &nbsp;
                        </>
                      )}
                  </span>
                ))}
              {selectedClient.length > MAX_DISPLAY_COUNT && (
                <>
                  {", "}
                  <Badge className="badge">
                    +{selectedClient.length - MAX_DISPLAY_COUNT}
                  </Badge>
                </>
              )}
            </>
          ) : (
            "Select a Client / Multiple Clients"
          )}
        </Button>
        <Label className="label">Select Template</Label>
        <Dropdown
          placeholder={selectedTemplate || "Select Template"}
          selectedKey={selectedTemplate}
          options={template.map((templates: Template) => ({
            key: templates.CommunicationTemplateId,
            text: templates.Title,
            body: templates.Body,
          }))}
          onChange={handleDropdownChangetemplate}
          styles={dropdownStyles}
        />
      </div>
      {selectedTemplate.length > 0 && (
        <>
          <div className="send_mail_client">
            <Label className="label">From :</Label>
            <Label>{userProfileInfo.user_email || ""}</Label>
            <Label className="label">Cc : </Label>
            <Label>{userProfileInfo.user_email || ""}</Label>
          </div>
        </>
      )}
      <>{console.log("previewList====", previewList)}</>
      <div>
        {previewList.length > 0 &&
          selectedTemplate.length > 0 &&
          previewList.map((item: any, index: number) => (
            <Accordion
              key={index}
              collapsible
              defaultOpenItems={index === 0 ? [index] : []}
            >
              <AccordionItem value={index}>
                <AccordionHeader>
                  {item.placeholders.client_name}
                </AccordionHeader>
                {item.body && (
                  <AccordionPanel className="custom_accordion">
                    {enableEdit &&
                    selectedItemId === item.customer.customer_id ? (
                      <>
                        <Dropzone
                          onDrop={(acceptedFiles: File[]) => {
                            if (acceptedFiles.length > 0) {
                              setUploadedFiles((prevFiles) => {
                                const newFiles = { ...prevFiles };
                                if (!newFiles[item.customer.customer_id]) {
                                  newFiles[item.customer.customer_id] = [];
                                }
                                newFiles[item.customer.customer_id].push(
                                  acceptedFiles[0]
                                );
                                return newFiles;
                              });
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "flex-end" })}>
                              <input {...getInputProps()} />
                              <AttachRegular
                                fontSize="20px"
                                onClick={() =>
                                  setSelectedItemId(item.customer.customer_id)
                                }
                              />
                            </div>
                          )}
                        </Dropzone>
                        <ReactQuill
                          value={item.body}
                          onChange={(value) => {
                            const updatedList = [...previewList];
                            updatedList[index].body = value;
                            setPreviewList(updatedList);
                          }}
                          modules={modules}
                          style={{ border: "none", padding: 0 }}
                        />
                        <div className="file_upload_list">
                          {uploadedFiles[item.customer.customer_id] &&
                            uploadedFiles[item.customer.customer_id].map(
                              (file: any, index: number) => (
                                <div
                                  className="file_upload_detail"
                                  key={`${item.customer.customer_id}-${index}`}
                                >
                                  <div>
                                    <p>{file.name}</p>
                                    <span style={{ fontSize: 12 }}>
                                      {(file.size / 1024).toFixed(2)} KB
                                    </span>
                                  </div>

                                  <DismissRegular
                                    onClick={() =>
                                      removeFile(
                                        item.customer.customer_id,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              )
                            )}
                        </div>
                        <div className="action_buttons">
                          <Button
                            className="asc-button-primary"
                            appearance="primary"
                            onClick={() => {
                              setEnableEdit(false);
                              setSelectedItemId("");
                            }}
                            size="small"
                          >
                            Cancel
                          </Button>
                          <Button
                            appearance="secondary"
                            onClick={() => {
                              setEnableEdit(false);
                              setSelectedItemId("");
                              setPreviewList((prevList: any) =>
                                prevList.map((item: any, i: number) =>
                                  i === index ? { ...item, edited: true } : item
                                )
                              );
                            }}
                            size="small"
                          >
                            Save
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-end">
                          <Button
                            icon={<Edit24Filled />}
                            size="small"
                            style={{ border: "none" }}
                            onClick={() => {
                              setEnableEdit(true);
                              setSelectedItemId(item.customer.customer_id);
                            }}
                          />
                        </div>
                        <div
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        ></div>
                      </>
                    )}
                  </AccordionPanel>
                )}
              </AccordionItem>
            </Accordion>
          ))}
      </div>
      {selectedClient.length > 0 && selectedTemplate.length > 0 && (
        <div className="message_buttons">
          <Button appearance="secondary" onClick={resetPage} size="small">
            Cancel
          </Button>
          <Button
            className="asc-button-primary"
            appearance="primary"
            onClick={handleContinue}
            size="small"
          >
            {selectedClient.length === 1 ? "Send" : "Send All"}
          </Button>
        </div>
      )}
      <Dialog
        open={showClientSelection}
        onOpenChange={(event, data) => setShowClientSelection(data.open)}
      >
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <ClientSelection
                handleClientSelection={handleClientSelection}
                setClientList={setClients}
              />
            </DialogContent>
            <DialogActions>
              <Button
                appearance="secondary"
                onClick={() => setShowClientSelection(false)}
                size="small"
              >
                Close
              </Button>
              <Button
                className="asc-button-primary"
                appearance="primary"
                onClick={() => setShowClientSelection(false)}
                size="small"
              >
                Select
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {showConfirmation && (
        <Dialog
          open={showConfirmation}
          onOpenChange={(event, data) => setShowConfirmation(data.open)}
        >
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Confirmation for sending mail</DialogTitle>
              <DialogActions>
                <Button
                  appearance="secondary"
                  onClick={() => handleConfirmation(false)}
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  className="asc-button-primary"
                  appearance="primary"
                  onClick={() => handleSendMail()}
                  size="small"
                >
                  Send Email
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
      {sendingMail && (
        <Dialog
          open={sendingMail}
          onOpenChange={(event, data) => setSendingMail(data.open)}
        >
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Sending Mail</DialogTitle>
              <DialogContent>
                {mailStatus.map((item: any, index) => (
                  <div key={index} className="mail_status">
                    {item.status === "completed" ? (
                      <PresenceBadge status="available" />
                    ) : (
                      <Spinner size="tiny" />
                    )}
                    <span>
                      {item.customer.last_name}, {item.customer.first_names}
                    </span>
                  </div>
                ))}
              </DialogContent>

              <DialogActions>
                <Button
                  appearance="secondary"
                  disabled={mailCount == totalMails ? false : true}
                  onClick={() => {
                    setSendingMail(false);
                    resetPage();
                  }}
                  size="small"
                >
                  Close
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      )}
    </div>
  );
};

export default TemplateDropdown;
