import { Tree, TreeItem, TreeItemLayout } from "@fluentui/react-components";
import { TreeNode } from "../../../shared/extractTypes";

export const renderTree = (
    data: TreeNode[],
    handleOptionClick: (
      value: string,
      label: string,
      itemType: string,
      data: any,
      datatype: any,
      table: any,
      fieldName?:any
    ) => void
  ): React.ReactNode => {
    return data.map((node) => {
      if (node.items) {
        return (
          <TreeItem itemType="branch" key={node.label}>
            <TreeItemLayout>{node.label}</TreeItemLayout>
            <Tree>{renderTree(node.items, handleOptionClick)}</Tree>
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            itemType="leaf"
            key={node.fieldName}
            onClick={() =>
              handleOptionClick(
                node.fieldName || "",
                node.name || "",
                "leaf",
                data,
                node.datatype,
                node.table,
                node.fieldName
              )
            }
          >
            <TreeItemLayout>{node.name}</TreeItemLayout>
          </TreeItem>
        );
      }
    });
  };
  
  export const renderConditionTree = (
    data: TreeNode[],
    handleConditionOptionClick: (
      value: string,
      label: string,
      itemType: string,
      data: any,
      datatype: any,
      table: any,
      fieldName?:any
    ) => void
  ): React.ReactNode => {
    return data.map((node) => {
      if (node.items) {
        return (
          <TreeItem itemType="branch" key={node.label}>
            <TreeItemLayout>{node.label}</TreeItemLayout>
            <Tree>
              {renderConditionTree(node.items, handleConditionOptionClick)}
            </Tree>
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            itemType="leaf"
            key={node.fieldName}
            onClick={() =>
              handleConditionOptionClick(
                node.fieldName || "",
                node.name || "",
                "leaf",
                data,
                node.datatype,
                node.table,
                node.fieldName
              )
            }
          >
            <TreeItemLayout>{node.name}</TreeItemLayout>
          </TreeItem>
        );
      }
    });
  };
  