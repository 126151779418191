import {
  Button,
  Caption1,
  Caption1Strong,
  Label,
  Subtitle2,
} from "@fluentui/react-components";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_RowSelectionState,
} from "material-react-table";
import {
  EyeOff24Filled,
  DocumentBulletList20Regular,
  Dismiss24Regular,
  Eye24Filled,
} from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { setDialogModalOptions } from "../../../redux/modelDialogReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import apiServices from "../../../service";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
} from "@fluentui/react-components";
import ReactQuillComponent from "../../../components/ReactQuillComponent";
import {
  convertStringToArray,
  formatedDate,
  formatedTime,
  getClientNoteCategories,
} from "../../../utils";
import ContactHistoryDetails from "./ContactHistoryDetails";

interface PropsType {
  [x: string]: any;
}

const ContactHistory = (props: PropsType) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [contactHistoryData, setContactHistoryData] = useState<any>([]);

  const [noteData, setNoteData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [caseData, setCaseData] = useState([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [hide, setHide] = useState<Boolean>(false);
  const [columnType, setColumnType] = useState<String | null>();
  const [columnId, setColumnId] = useState<String | null>();
  const [contactHistoryItems, setContactHistoryItems] = useState<any>([]);
  const [clientDataById, setClientDataById] = useState([]);

  useEffect(() => {
    updateContectHistory();
  }, [caseData, noteData]);
  const updateContectHistory = () => {
    setContactHistoryData([...noteData, ...clientData, ...caseData]);
  };

  useEffect(() => {
    const keyForTrue: string | undefined = Object.keys(rowSelection).find(
      (key) => rowSelection[key] === true
    );
    if (keyForTrue !== undefined) {
      let selectedValue = contactHistoryData[keyForTrue];
      setColumnType(selectedValue?.type);
      setColumnId(selectedValue?.id);
    }
  }, [rowSelection]);

  useEffect(() => {
    getActionListData(props.activeCustomerId);
  }, []);

  // useEffect(() => {
  //   // console.log(contactHistoryItems, "History data");
  // }, [contactHistoryItems]);

  function getCaseCategory(categoryNumber: any) {
    if (categoryNumber === 0) {
      return "Undefined";
    } else if (categoryNumber === 1) {
      return "Policy";
    } else if (categoryNumber === 2) {
      return "Asset Investment";
    } else if (categoryNumber === 3) {
      return "Asset Shares";
    } else if (categoryNumber === 4) {
      return "Asset Home and Personal";
    } else if (categoryNumber === 5) {
      return "Asset Bank";
    } else if (categoryNumber === 6) {
      return "Liability Mortgage";
    } else if (categoryNumber === 7) {
      return "Liability Loan and Hire Purchase";
    } else if (categoryNumber === 8) {
      return "Liability Credit Card";
    }
  }

  const getActionListData = (userId: any) => {
    apiServices.clientHistory
      .getClientHistory(userId)
      .then((response) => {
        if (response.data && response.data.data) {
          let resultData: any = [];
          Object.keys(response.data.data).map((key: any, index) => {
            for (const key in response.data.data[index]) {
              if (response.data.data[index].hasOwnProperty(key)) {
                if ("notes" === key) {
                  // console.log(key, "Is note key");
                  response.data.data[index][key].map((object: any) => {
                    resultData.push({
                      date: object.note_date,
                      id: object.note_id,
                      type: "Notes",
                      time: object.note_time,
                      category: getClientNoteCategories(object.category),
                      contact_type: object.note_type,
                      note_text: object.note_text,
                      status: "",
                      file_link: object.file_link,
                    });
                  });
                } else if ("client_action" === key) {
                  // console.log(key, "Is client key");
                  response.data.data[index][key].map((object: any) => {
                    resultData.push({
                      category: key,
                      id: object.appointment_id,
                      type: "Client",
                      date: object.appointment_date,
                      time: object.appointment_time,
                      contact_type: object.appointment_type,
                      note_text: object.appointment_note,
                      status: object.appointment_status,
                      file_link: "",
                      assigned: object.appointment_assigned,
                    });
                  });
                } else {
                  response.data.data[index][key].map((object: any) => {
                    // console.log(key, "Is case key");
                    resultData.push({
                      id: object.case_id,
                      business_id: object.business_id,
                      type: "Case",
                      date: object.business_date,
                      time: "",
                      category: getCaseCategory(object.business_category),
                      contact_type: "",
                      note_text: object.business_action,
                      status: object.business_status,
                      business_category: object.business_category,
                      assigned: object.business_assigned,
                      case_type: object.case_type,
                      file_link: "",
                    });
                  });
                }
              }
            }
          });
          setContactHistoryItems(resultData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = useMemo<MRT_ColumnDef<{ [key: string]: any }>[]>(
    () => [
      {
        accessorKey: "date",
        header: "Date",
        Cell: ({ row }: any) => <>{formatedDate(row.original.date)}</>,
      },
      {
        accessorKey: "time",
        header: "Time",
        Cell: ({ row }: any) => <>{formatedTime(row.original.time)}</>,
      },
      {
        accessorKey: "category",
        header: "Category",
        // Cell: ({ row }: any) => (
        //   <>{getClientNoteCategories(row.original.category)}</>
        // ),
      },
      {
        accessorKey: "contact_type",
        header: "Contact Type",
      },
      {
        accessorKey: "note_text",
        header: "Note Text",
        Cell: ({ row }: any) => (
          <>
            <ReactQuillComponent value={row.original.note_text} />
          </>
        ),
      },
      {
        accessorKey: "file_link",
        header: "File Link",
      },
      {
        accessorKey: "assigned",
        header: "Assigned",
      },
      {
        accessorKey: "status",
        header: "Status",
      },
    ],
    []
  );

  function onHideClick() {
    if (!hide) {
      setContactHistoryData([...noteData]);
      setHide(true);
    } else {
      setContactHistoryData([...noteData, ...clientData, ...caseData]);
      setHide(false);
    }
  }

  const getClientDetail = (id: string) => {
    apiServices.client
      .getDetail(id)
      .then((response: any) => {
        setClientDataById(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const activeItem = { case_type: "Contact History" };

  return (
    <>
      <header className="cl-page-header">
        <Subtitle2>Contact History </Subtitle2>

        <nav className="cl-breadcrumbs">
          <ul>
            <li
              onClick={() => {
                props.setVerticalTab("Client");
              }}
            >
              <a>
                {" "}
                <Caption1>Client Detail</Caption1>
              </a>

              <span className="cl-separator"> / </span>
            </li>
            <li>
              <Caption1Strong>Contact History </Caption1Strong>
            </li>
          </ul>
        </nav>
      </header>
      <div className="">
        <div className="">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "0.4rem",
            }}
          >
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
              size="small"
              icon={<DocumentBulletList20Regular />}
            >
              Details
            </Button>
          </div>
          <MaterialReactTable
            columns={columns}
            data={contactHistoryItems}
            enableGlobalFilter={false}
            enableColumnActions={false}
            enableRowSelection
            enableMultiRowSelection={false}
            enableRowActions={false}
            positionToolbarAlertBanner="none"
            muiTableBodyRowProps={({ row }) => ({
              onClick: () => {
                dispatch(
                  setDialogModalOptions({
                    open: true,
                    contact_history_data: row.original,
                    type: row.original.type,
                    setActiveItem: props.setActiveItem,
                    activeClient: props.activeClient,
                    customerId: props.activeCustomerId,
                    close: {
                      onclick: () => {
                        dispatch(setDialogModalOptions({ open: false }));
                      },
                      label: "Cancel",
                    },
                    //  no: {
                    //   onclick: () => {
                    //     dispatch(setDialogModalOptions({ open: false }));
                    //   },
                    //   label: "Save",
                    //   type: "primary",
                    // },
                  })
                );
              },
              sx: { cursor: "pointer" },
            })}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "Actions", //change header text
                // size: 300, //make actions column wider
                muiTableHeadCellProps: {
                  align: "center",
                },
                muiTableBodyCellProps: {
                  align: "center",
                },
              },
            }}
            enableStickyHeader
            renderTopToolbarCustomActions={({ table }) => (
              <>
                {!convertStringToArray(
                  process.env.REACT_APP_HIDE_CTA_BUTTON
                ).includes("case/client_action_btn") ? (
                  <Button
                    disabled={
                      !convertStringToArray(
                        process.env.REACT_APP_DISABLE_CTA_BUTTON
                      ).includes("case/client_action_btn")
                        ? false
                        : true
                    }
                    onClick={onHideClick}
                    size="small"
                    icon={hide ? <Eye24Filled /> : <EyeOff24Filled />}
                  >
                    {hide ? "Show " : "Hide "}Case/Client Action
                  </Button>
                ) : (
                  ""
                )}
              </>
            )}
            positionActionsColumn="last"
            state={{ rowSelection }}
            onRowSelectionChange={setRowSelection}
          />
        </div>
        <OverlayDrawer
          position={"end"}
          open={isOpen}
          onOpenChange={(_, { open }) => setIsOpen(open)}
          className={"common-drawer"}
        >
          <DrawerHeader>
            <DrawerHeaderTitle
              action={
                <Button
                  appearance="subtle"
                  aria-label="Close"
                  icon={<Dismiss24Regular />}
                  onClick={() => setIsOpen(false)}
                />
              }
            >
              Details
            </DrawerHeaderTitle>
          </DrawerHeader>

          <DrawerBody>
            <ContactHistoryDetails
              activeItem={activeItem}
              customerId={props.activeCustomerId}
              columnType={columnType}
              columnId={columnId}
            />
          </DrawerBody>
        </OverlayDrawer>
      </div>
    </>
  );
};

export default ContactHistory;
